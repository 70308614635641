import { Component, OnInit, ViewChild, ElementRef, ViewChildren, ChangeDetectorRef, QueryList } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {RfpService, CommonService, DashboardStoreService, AuthenticationService} from '../../_services';
import { ApiResponse } from '../../_models/response';
import { DatePicker } from '../../const';;
import { VenderService } from "../../_services/vender.service";
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ExcelDocHandler } from '../../_models/excelData';
import {MdbTableDirective, ToastService} from 'ng-uikit-pro-standard';
import { Rfp, TabsConfig, QuestionarieStates } from 'src/app/_models/rfp';
import { ReviewComponent } from './review/review.component';
import { WorksheetComponent } from './worksheet/worksheet.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QuestionsComponent } from './questions/questions.component';

declare var stripe: any;
declare var elements: any;

type sheetConfig = {
  sectionId: number,
  sheetName?: string,
  sheetDataLoaded?: boolean
};

@Component({
  selector: 'app-rfp-dashboard',
  templateUrl: './rfp-dashboard.component.html',
  styleUrls: ['./rfp-dashboard.component.scss']
})
export class RfpDashboardComponent implements OnInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild("paymentModal", { static: true }) PaymentModal: any;
  @ViewChild("questionModal", { static: true }) questionModal: any;
  @ViewChild("reviewTab", { static: false }) reviewTab: ReviewComponent;
  @ViewChild("worksheetTab", { static: false }) worksheetTab: WorksheetComponent;
  @ViewChild("dashboardTab", { static: false }) dashboardTab: DashboardComponent;
  @ViewChild("questionTab", { static: false }) questionTab: QuestionsComponent;
  // @ViewChildren("spreadsheet") public worksheet: QueryList<jexcel>;
  worksheet: ElementRef;
  sheetsConfig: sheetConfig[] = [];
  excel: any;
  subs = new Subscription();
  customStripeFormGroup: FormGroup;
  addDelegateFormGroup: FormGroup;
  answerAttachmentFormGroup: FormGroup;
  worksheetHandler: ExcelDocHandler;
  newAnswers: number;
  constructor(
    private activatedRoute: ActivatedRoute,
    private rfpService: RfpService,
    public dashboardStoreService: DashboardStoreService,
    private router: Router,
    private venderService: VenderService,
    public commonservice: CommonService,
    private toastService: ToastService,
    private auth: AuthenticationService
  ) { }

  RfpId: string = "";
  rfp: Rfp;
  serverError = "";
  rfpsErrorMsg = "";
  rfpsMsg = "";
  DatePicker = DatePicker;
  status = '';
  subStatus = '';
  delegateStatus = '';
  delegateAddFormError = "";
  ReviewError = "";
  ProgressChart = { color: "43D1C3", animate: {}, NotStarted: 0, InProgress: 0, Complete: 0, }
  tabsConfig: TabsConfig = <TabsConfig>{};
  isRfpAmtSetFlag = false;
  rfxAmt = 0;
  card: any;
  doPaymentError = "";
  isPaymentDone = false;
  rfxPayer;
  isBuyerData = false;
  Roles: Array<any> = [{ value: 3, label: 'Admin', }, { value: 4, label: 'User', }];
  rfpStatus = "";
  rfpSubStatus = "";
  routeSubStatus = "";
  expired: boolean;
  question = '';

  ngOnInit() {
    this.activatedRoute.data.subscribe((data) => {
      this.rfp = data.rfpdashboardData.rfpData;
      localStorage.setItem('rfp_details', JSON.stringify(this.rfp));
      this.expired = this.rfpService.isRfpExpired(this.rfp);

      if (this.expired) return;

      this.rfp.id = data.rfpdashboardData.rfpIdAndStatus.rfpId;
      this.RfpId = "" + this.rfp.id;

      if (this.rfp.invite_type == '') {
        this.rfpStatus = "" + this.rfp.invite.status;
        this.rfpSubStatus = "" + this.rfp.invite.rfpStatus;
      } else {
        this.rfpStatus = "" + data.rfpdashboardData.rfpIdAndStatus.rfpStatus;
        this.rfpSubStatus = data.rfpdashboardData.rfpIdAndStatus.rfpSubStatus;
      }

      this.routeSubStatus = data.rfpdashboardData.rfpIdAndStatus.rfpSubStatus;
      this.dashboardStoreService.rfpDetailsSubject.next(this.rfp);
      if (this.rfp != null) {
        let rfxPayer = this.rfp.payer;
        if (rfxPayer == 'vn') {
          // console.log('vendor gonna pay');
          this.isRfxPaymentDoneByMe(this.rfp.id, data.rfpdashboardData.isRfxPaymentDoneByMe);
        } else if (rfxPayer == 'is') {
          this.showHideTabs(this.rfp);
          // console.log('issuer paid already');
        } else {
          this.showHideTabs(this.rfp);
          // console.log('err: in Rfp details response, payer is not defined');
        }

        this.delegateStatus = this.rfp.invite_type;
      }
      this.dashboardStoreService.updateTabsConfig(this.tabsConfig);
      this.dashboardStoreService.init(data.rfpdashboardData.rfpIdAndStatus.rfpId);
    });

    // this.activatedRoute.params.subscribe( (param: any) => {
    //   if(param.Id){
    //     this.RfpId = param.Id;
    //     this.rfpStatus =  param.status;

    //     this.rfpSubStatus =  param.subStatus;

    //     this.dashboardStoreService.updateTabsConfig(this.tabsConfig)
    //     this.dashboardStoreService.init(param);
    //   }
    // });

    // this.dashboardStoreService.rfpDetailsSubject.asObservable().subscribe((res:any)=>{
    // });

    this.dashboardStoreService.tabsConfig$.subscribe((res: any) => {
      this.tabsConfig = res;
      // console.log(this.tabsConfig);
    });

    this.customStripeFormGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      postal_code: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
    });

    this.dashboardStoreService.paymentDone$.subscribe((isPaymentDone: boolean) => {
      this.isPaymentDone = isPaymentDone;
      // console.log('payment' + this.isPaymentDone);
      if (this.rfp != null)
        this.showHideTabs(this.rfp);
    });
  }

  getRfxAmtFromSettings() {
    this.venderService.getSettings().subscribe((result: any) => {
      if (result.code == 200) {
        let isRfpAmtSetFlag = false;
        result.data[0].forEach(ele => {
          // console.log('element', ele);
          if (this.rfp.payer == 'vn') {
            // console.log('inside vendor if');
            // console.log('element.key', ele.key);
            if (ele.key == "vendor_fee") {
              // console.log('inside vendor_fee if');
              isRfpAmtSetFlag = true;
              this.rfxAmt = ele.value;
            }
          } else if (this.rfp.payer == 'is') {
            // console.log('inside issuer if');
            if (ele.key == "issuer_fee") {
              // console.log('inside issuer_fee if');
              this.rfxAmt = ele.value;
              isRfpAmtSetFlag = true;
            }
          }
        });

        if (!isRfpAmtSetFlag) {
          this.rfxAmt = 0;
          isRfpAmtSetFlag = false;
          // console.log('err: in settings table issuer_fee or vendor_fee key is not set.');
        } else {
          // this.pay();
        }
        // console.log('this.rfp.payer', this.rfp.payer);
        // console.log('isRfpAmtSetFlag', isRfpAmtSetFlag);
        // console.log('this.rfxAmt', this.rfxAmt);
      } else {
        // console.log('inside getRfxAmtFromSettings else');
        // isRfpAmtSetFlag = false;
      }
      this.loadCustomePaymentForm1();
    });
  }

  loadCustomePaymentForm1() {
    let $this = this;
    let msg = "";

    if (this.tabsConfig.is_secured_questions == false) {
      msg = `Do you want to continue pay. RFx fees $${this.rfxAmt} ? To complete the bid process.`;
    } else {
      msg = `Do you want to continue pay. RFx fees $${this.rfxAmt} ? After that you can proceed with the RFx questions and worksheets to complete the bid processing.`;
    }

    Swal.fire({
      title: 'Confirm Payment.',
      text: msg,
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      // console.log('showConfirmPaymentBox result', result);
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.PaymentModal.show();
        //   Swal.fire('Loading payment  gateway.!', '', 'success')
        // this.loadStripePaymentGateway();

        // this.loadStripe();

      } else if (result.isConfirmed == false) {
        //   Swal.fire('Payment cancel.', '', 'info');
      }
    })
  }



  isRfxPaymentDoneByMe(rfxId, result) {
    // console.log('isRfxPaymentDoneByMe', result);
    if (result.code == 200) {
      if (result.data[0].isPaid == true) {
        this.dashboardStoreService.updatePaymentStatus(true);
      } else {
        this.dashboardStoreService.updatePaymentStatus(false);
      }
    } else {
      this.dashboardStoreService.updatePaymentStatus(false);
    }
    // this.showHideTabs( this.rfp );
  }

  showConfirmPaymentBox() {
    this.getRfxAmtFromSettings();
  }

  onShow(event) {
    if (event.target.nativeElement.innerText.toLowerCase() == "review") {
      this.reviewTab.getReviewTab();
    } else if (event.target.nativeElement.innerText.toLowerCase() == "dashboard") {
      this.dashboardTab.reloadCharts();
    }
  }

  ngAfterViewInit() {

    // this.card = elements.create('card');
    // this.card.mount('#card-element');
    // this.card.addEventListener('change', event => {
    //   const displayError = document.getElementById('card-errors');
    //   if (event.error) {
    //     displayError.textContent = event.error.message;
    //   } else {
    //     displayError.textContent = '';
    //   }
    // });

    // this.cd.detectChanges();
  }

  acceptRfp(accepted) {
    let status = accepted ? "accepted" : "rejected";
    this.rfpService.acceptRfp(this.RfpId, status).subscribe((x: ApiResponse) => {
      if (x.code == 200) {
        // this.router.navigate(['/inprogress-rfps']);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/rfp-dashboard/' + this.RfpId + '/accepted/progress']);

        //this.workSheetComponent.ngOnInit();
      } else {
        // this.showError(this.rfpsErrorMsg +" "+ x.message);
        // this.rfpsErrorMsg += x.message;
      }
    }, err => {
      // this.showError(this.rfpsErrorMsg +" "+ err);

      // this.rfpsErrorMsg += err;
    })
  }

  acceptDelgateRfp(accepted, type) {
    let status = accepted ? "Accepted" : "Rejected";
    this.rfpService.acceptdelegateRfp(this.RfpId, status, type).subscribe((x: ApiResponse) => {
      if (x.code == 200) {
        this.router.navigate(['/inprogress-rfps']);
      } else {
        // this.showError(this.rfpsErrorMsg +" "+ x.message);
        // this.rfpsErrorMsg += x.message;
      }
    }, err => {
      // this.showError(this.rfpsErrorMsg +" "+ err);

      // this.rfpsErrorMsg += err;
    })
  }
  // async pay() {
  //   this.doPaymentError = "";
  //   const { token, error } = await stripe.createToken(this.card);
  //   if (error) {
  //     this.doPaymentError = error;
  //   } else {

  //     this.rfpService.makePayment(this.RfpId, token, this.customStripeFormGroup.value).subscribe((res: any) => {
  //       if (res.code == 200) {
  //         this.PaymentModal.hide();
  //         setTimeout(() => {
  //           this.tabsConfig.show_all = true;
  //           this.tabsConfig.payment_needed = false;

  //         }, 200);
  //       } else {
  //         this.doPaymentError = res.message;
  //       }
  //     }, err => {
  //       this.doPaymentError = err;
  //     });
  //   }
  // }
  notifyPaymentSuccess(isSuccess: boolean) {
    if (isSuccess) {
      this.PaymentModal.hide();
      setTimeout(() => {
        this.tabsConfig.show_all = true;
        this.tabsConfig.payment_needed = false;
        this.worksheetTab.getWorsheetData();
        this.questionTab.reloadQuestionsTab();
      }, 500);
    }
  }

  showHideTabs(RfpData) {

    let is_secured_questions = RfpData.is_secured_questions;
    let payer = RfpData.payer;
    let inviteType = RfpData.invite_type;
    this.tabsConfig.rfpStatus = this.rfpStatus;
    this.tabsConfig.rfpSubStatus = this.rfpSubStatus;
    this.tabsConfig.is_secured_questions = is_secured_questions;
    this.tabsConfig.payer = payer;
    /**
     * if payer is IS show all tabs and no payment needed
     * if payer is VN and is_secured_question true don't show question and worksheet else proceed to payment
     * if payer is VN and is_secured_question false show question and worksheet and proceed to payment
     */
    if (payer == 'is') {

      this.tabsConfig.payment_needed = false;
      if (this.tabsConfig.rfpStatus == "pending") {
        this.tabsConfig.show_all = false;
        this.tabsConfig.questionarie = QuestionarieStates.Disabled;
        this.tabsConfig.delegate = "hidden";
      } else {
        this.tabsConfig.show_all = true;
        this.tabsConfig.questionarie = QuestionarieStates.Visible;
        this.tabsConfig.delegate = "visible";
      }
      this.dashboardStoreService.updateTabsConfig(this.tabsConfig);

    } else if (payer == 'vn') {
      if(RfpData.hasNoWorksheet == 1){
        this.tabsConfig.isHideWorksheet = true;
      }
      if (is_secured_questions == false) {

        if (this.isPaymentDone) {
          this.tabsConfig.payment_needed = false;
          this.tabsConfig.questionarie = QuestionarieStates.Visible;

          if (this.tabsConfig.rfpStatus == "pending") {
            this.tabsConfig.show_all = false;
            this.tabsConfig.questionarie = QuestionarieStates.Disabled;
            this.tabsConfig.delegate = "hidden";

          } else {
            this.tabsConfig.show_all = true;
            this.tabsConfig.questionarie = QuestionarieStates.Visible;
            this.tabsConfig.delegate = "visible";
            this.tabsConfig.mainSupplierCanSubmit = false;
          }
        } else {
          this.tabsConfig.show_all = false;
          if (this.tabsConfig.rfpStatus == "pending") {
            this.tabsConfig.questionarie = QuestionarieStates.Disabled;
            this.tabsConfig.delegate = "hidden";
          } else {
            this.tabsConfig.questionarie = QuestionarieStates.Visible;
            this.tabsConfig.delegate = "visible";
            this.tabsConfig.mainSupplierCanSubmit = false;
          }
          this.tabsConfig.payment_needed = true;
        }
      } else if (is_secured_questions == true) {
        this.tabsConfig.show_all = false;

        if (this.isPaymentDone) {
          this.tabsConfig.payment_needed = false;
          if (inviteType == 'delegate') {
            if (this.tabsConfig.rfpStatus == "pending") {
              this.tabsConfig.questionarie = QuestionarieStates.Disabled;
              this.tabsConfig.delegate = "hidden";
              this.tabsConfig.show_all = false;
            } else {
              this.tabsConfig.show_all = true;
              this.tabsConfig.questionarie = QuestionarieStates.Visible;
              this.tabsConfig.delegate = "visible";
              this.tabsConfig.mainSupplierCanSubmit = false;
            }
          } else {
            this.tabsConfig.questionarie = QuestionarieStates.Visible;
            this.tabsConfig.show_all = true;
            this.tabsConfig.delegate = "visible";
          }
        } else {
          this.tabsConfig.show_all = false;
          if (this.tabsConfig.rfpStatus == "pending") {
            // acceptAndPay means Show only Button that shows Please Accept and Pay
            this.tabsConfig.questionarie = QuestionarieStates.AcceptAndPay;
            this.tabsConfig.delegate = "hidden";
          } else {
            this.tabsConfig.questionarie = QuestionarieStates.PleasePay;
            this.tabsConfig.delegate = "visible";
          }
          this.tabsConfig.payment_needed = true;
        }
      } else {
        console.log('********************************** is_secured_questions is not set **********************************');
      }
    }else if(payer == 'free'){
      this.tabsConfig.payment_needed = false;
      this.tabsConfig.show_all = true;
      this.tabsConfig.questionarie = QuestionarieStates.Visible;
      this.tabsConfig.delegate = "visible";
      if(RfpData.hasNoWorksheet == 1){
        this.tabsConfig.isHideWorksheet = true;
      }
      this.dashboardStoreService.updateTabsConfig(this.tabsConfig);
    }else {
      console.log('********************************** payer is not set **********************************');
    }

    if (this.tabsConfig.rfpSubStatus == 'sent') {
      this.tabsConfig.questionarie = QuestionarieStates.Disabled;
      this.tabsConfig.show_all = false;
    }
    this.dashboardStoreService.updateTabsConfig(this.tabsConfig);
  }

  createQuestion(question: string) {
    // save question
    this.venderService.createQuestion(this.RfpId, question)
      .subscribe((res: any) => {
        this.toastService.success('Your question has been sent to the buyer', '', { opacity: 1 });
        this.question = '';
        this.questionModal.hide();
      }, (err) => {
        this.toastService.error(err, '', { opacity: 1 });
      });
  }

  questionsAvailable() {
    return true;
    //return this.status === 'accepted' && !this.tabsConfig.payment_needed;
  }

  canEdit() {
    return !(this.rfp.invite_type === 'delegate' && this.rfp['delegate_role'] === 'v');
  }


}
