import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompaniesService } from '../_services/companies.service';
import { ApiResponse } from '../_models/response';
import { CommonService, AuthenticationService, UserService } from '../_services';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment.prod';

@Component({
  selector: 'app-my-company-edit',
  templateUrl: './my-company-edit.component.html',
  styleUrls: ['./my-company-edit.component.scss']
})
export class MyCompanyEditComponent implements OnInit {
  userId = 0;
  company_id = 0;
  compnyFormError = "";
  CompanyForm: FormGroup;
  RoutePrefix = "";
  constructor(
    private auth: AuthenticationService,
    private companiesService: CompaniesService,
    public commonservice: CommonService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    if (!this.auth.currentUserValue.isAdmin) {
      this.router.navigate([this.RoutePrefix + '/my-profile']);
      return;
    }
    this.userId = this.auth.currentUserValue.userid;
    this.CompanyForm = new FormGroup({
      companyname: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      phoneno: new FormControl('', [Validators.required]),
      websites: new FormControl('', []),
      companysize: new FormControl('', [Validators.required])
    });
    this.compnyFormError = "";

    this.userService.getUser(this.userId).subscribe((u: ApiResponse) => {
      if (u.code == 200) {
        let user = u.data[0];
        this.company_id = user.company_id;
        this.companiesService.getCompany(this.auth.currentUserValue.userid).subscribe((r: ApiResponse) => {

          if (r.code == 200) {
            let d = r.data[0].company;
            if (d) {
              this.commonservice.SetFormValue(this.CompanyForm, "companyname", d.name);
              this.commonservice.SetFormValue(this.CompanyForm, "address", d.address);
              this.commonservice.SetFormValue(this.CompanyForm, "description", d.description);
              this.commonservice.SetFormValue(this.CompanyForm, "pincode", d.pincode);

              this.commonservice.SetFormValue(this.CompanyForm, "phoneno", d.phone);
              this.commonservice.SetFormValue(this.CompanyForm, "websites", d.website);
              this.commonservice.SetFormValue(this.CompanyForm, "companysize", d.companySize);

            } else {
              this.compnyFormError = r.message;
            }
          }
          else {
            this.compnyFormError = r.message;
          }
        }, err => {
          this.compnyFormError = err;
        })
      } else {
        this.compnyFormError = u.message;
      }

    }, er => {
      this.compnyFormError = er;
    })
  }
  updateCompny() {
    if (this.CompanyForm.valid) {
      this.compnyFormError = "";
      //update
      this.companiesService.updateCompanyProfile(this.CompanyForm.value).subscribe((u: ApiResponse) => {
        if (u.code == 200) {
          this.router.navigate([this.RoutePrefix + '/my-profile']);
        } else {
          this.compnyFormError = u.message;
        }
      }, err => {
        this.compnyFormError = err;
      });
    } else {
      this.commonservice.MarkAllAllCtrlDirty(this.CompanyForm);
    }
  }
}
