import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './public/login/login.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { AuthGuard } from './_guards';
import { RegisterComponent } from './public/register/register.component';
import { AuthGuardAdmin } from './_guards/auth.guard-admin';
import { DashboardComponent } from './rfps/rfp-dashboard/dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
//profile   // http://www.sourcecnx.com/rfp/public/api/v1/user/verify/MTQ5/66eb391f24dc4d1a58fbdcf670a17e40

import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyProfileEditComponent } from './my-profile-edit/my-profile-edit.component';
import { MyCompanyEditComponent } from './my-company-edit/my-company-edit.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MyRfpsComponent } from './rfps/my-rfps/my-rfps.component';
import { InProgressRfpsComponent } from './rfps/in-progress-rfps/in-progress-rfps.component';
import { AvailableRfpsComponent } from './rfps/available-rfps/available-rfps.component';
import { DelegateRfpsComponent } from './rfps/delegate-rfps/delegate-rfps.component';
import { RfpDashboardComponent } from './rfps/rfp-dashboard/rfp-dashboard.component';
import { VendorsComponent } from './vendors/vendors.component';
import { VerifyComponent } from './public/verify/verify.component';
import { VendorAddEditComponent } from './vendor-add-edit/vendor-add-edit.component';
import { VerifiedComponent } from './public/verified/verified.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { DelegateAddEditComponent } from "./delegate-add-edit/delegate-add-edit.component";
import { RfpDashboardResolver } from './rfps/rfp-dashboard/rfp-dashboard.resolver';
import { ImpersonateComponent } from './public/impersonate/impersonate.component';
import {RfpQuestionsComponent} from './rfps/rfp-questions/rfp-questions.component';

const routes: Routes = [
  {
    path: "",
    children: [
      { path: '', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'login', component: LoginComponent },
      { path: 'impersonate', component: ImpersonateComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'verify', component: VerifyComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
      { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthGuard] },
      { path: 'edit-my-profile', component: MyProfileEditComponent, canActivate: [AuthGuard] },
      { path: 'edit-my-company', component: MyCompanyEditComponent, canActivate: [AuthGuard] },
      { path: 'my-rfps', component: MyRfpsComponent, canActivate: [AuthGuard] },
      { path: 'inprogress-rfps', component: InProgressRfpsComponent, canActivate: [AuthGuard] },
      { path: 'available-rfps', component: AvailableRfpsComponent, canActivate: [AuthGuard] },
      { path: 'delegate-rfps', component: DelegateRfpsComponent, canActivate: [AuthGuard] },
      {
        path: 'rfp-dashboard/:Id/:status',
        component: RfpDashboardComponent, canActivate: [AuthGuard],
        resolve: {
          "rfpdashboardData": RfpDashboardResolver
        }
      },
      { path: 'rfp/:id/questions', component: RfpQuestionsComponent },
      { path: 'rfp-dashboard/:Id/:status/:subStatus', component: RfpDashboardComponent, canActivate: [AuthGuard],
        resolve: {
          "rfpdashboardData": RfpDashboardResolver
        }

      },
      { path: 'vendors', component: VendorsComponent, canActivate: [AuthGuard] },

      { path: 'vendors-add-edit', component: VendorAddEditComponent, canActivate: [AuthGuard] },
      { path: 'vendors-edit/:Id', component:  VendorAddEditComponent, canActivate: [AuthGuardAdmin] },

      // Deligates
      { path: 'rfxId/:rfxid/deligates-add-edit', component: DelegateAddEditComponent, canActivate: [AuthGuard] },
      { path: 'deligates-edit/:Id', component:  DelegateAddEditComponent, canActivate: [AuthGuardAdmin] },


      { path: 'verified', component: VerifiedComponent},
      { path: 'verified', component: ResetPasswordComponent},
      { path: 'reset/password', component: ResetPasswordComponent },//ResetPassword
      // otherwise redirect to home
      { path: '**', redirectTo: '' }]
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
