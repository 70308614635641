import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { from, of, Subscription, zip } from "rxjs";
import { filter, mergeMap, switchMap, tap } from "rxjs/operators";
import { SharedMethodService } from "../../../_services/shared-method.service";
import { RfpService, DashboardStoreService } from "../../../_services";
import * as jexcel from "jexcel-pro";
import { ApiResponse } from "src/app/_models/response";
import { ExcelDocHandler } from "src/app/_models/excelData";
import { QuestionarieStates } from "src/app/_models/rfp";
import { ToastService } from "ng-uikit-pro-standard";
//import Swal from 'sweetalert2';
type sheetConfig = {
  sectionId: number;
  sheetName?: string;
  sheetDataLoaded?: boolean;
};

@Component({
  selector: "app-worksheet",
  templateUrl: "./worksheet.component.html",
  styleUrls: ["./worksheet.component.scss"],
})
export class WorksheetComponent implements OnInit {
  @Input("rfp_id") RfpId: string;
  @Input("rfp_sub_status") rfpSubStatus: string;

  @Input("rfp_delegate_status") delegateStatus: string;
  @Input("payer") payer: any;
  @Input() canEdit: boolean;
  questionarieStates = QuestionarieStates;
  worksheet: ElementRef;

  subs = new Subscription();
  activeSheetIndex: number = 0;
  sheetsConfig: sheetConfig[] = [];
  tabsConfig: any;
  worksheetHandler: ExcelDocHandler;
  dataColumns: any;
  worksheetType: String;

  constructor(
    private sharedMethodService: SharedMethodService,
    private rfpService: RfpService,
    public dashboardStoreService: DashboardStoreService,
    private toastService: ToastService
  ) {}

  setWorksheet(ele) {
    this.worksheet = ele;

    this.subs.add(
      // gets selected tab index from worksheet
      this.sharedMethodService
        .getWorksheetSelectedTabIndex()
        .pipe(
          filter((res1) => {
            this.activeSheetIndex = res1;
            let activeSectionId = this.sheetsConfig[res1].sectionId;

            return (
              !this.sheetsConfig[res1].sheetDataLoaded && activeSectionId > 0
            );
          }),
          switchMap((res1: number) => {
            // change Observable response on subscribe to response of SheetData
            return this.rfpService.getsheetDataBySheetId(
              this.RfpId,
              this.sheetsConfig[res1].sectionId
            );
          })
        )
        .subscribe((newSheetData: ApiResponse) => {
          if (newSheetData.code == 200) {
            // show New tab's data on worksheet
            // console.log(newSheetData.data[0]);
            // return false;
            this.dataColumns = newSheetData.data[0];
            console.log(this.dataColumns);
            this.updateSheetByTabIndex(
              newSheetData.data[0],
              this.activeSheetIndex
            );
          }
        })
    );
    this.worksheet.nativeElement.jexcel = null;
  }
  getWorsheetData() {
    this.rfpService
      .getWorksheetTabsbyRfpId(this.RfpId)
      .pipe(
        switchMap((x: ApiResponse) => {
          // console.log(x);

          let lastTabIndex = x.data.length - 1;
          // zipping sheetData of last Sheet, coz, need to load data of active tab. other will be loaded on tabClick
          return zip(
            this.rfpService.getsheetDataBySheetId(
              this.RfpId,
              x.data[lastTabIndex].id
            ),
            of(lastTabIndex)
          );
        })
      )
      .subscribe((res: any[]) => {
        // LastSheetData
        let response: ApiResponse = res[0];

        // LastSheet index
        let lastTabIndex: number = res[1];

        if (response.code == 200) {
          // Loads data into the sheet[tabIndex]
          this.dataColumns = response.data[0];
          this.worksheetType =
            this.dataColumns["rfx_details"]["worksheet_type"];
          this.getWorksheetTabs();
        }
      });
  }

  ngOnInit() {
    // console.log(this.RfpId);
    this.subs.add(
      this.dashboardStoreService.getTabsConfig().subscribe((res: any) => {
        this.tabsConfig = res;
      })
    );
    this.getWorsheetData();
  }

  updateSheetByTabIndex(data: any, tabIndex: number) {
    this.dataColumns = data.columns;
    // console.log(data.columns)
    let sheet: any;
    if (this.worksheet.nativeElement.jexcel[tabIndex]) {
      sheet = this.worksheet.nativeElement.jexcel[tabIndex];
    } else {
      sheet = this.worksheet.nativeElement.jexcel;
    }

    // updates the data
    sheet.setData(data.data);
    let i = 0;
    let aTOz = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    try {
      for (let col of data.columns) {
        sheet.setHeader(i, col.title);
        if (col.hidden == true) {
          sheet.hideColumn(i);
        }

        if (col.required == true) {
          for (let d = 0; d < data.data.length; d++) {
            if (data.data[d].filter((value) => value !== "").length > 0) {
              sheet.setStyle(aTOz[i] + (d + 1), "background-color", "#f54040");
            }
          }
        }

        //   sheet.getColumnOptions(i).options.readOnly = col.readOnly;
        //   sheet.getColumnOptions(i).options.hidden = col.hidden;
        // sheet.getActiveSheet().
        // sheet.getActiveSheet().getColumnDimension('C').setVisible(false);

        i++;
      }
      let k = 0;
      for (let i of data.rowId) {
        sheet.setRowId(k, i);
        k++;
      }
    } catch {}

    this.sheetsConfig[tabIndex].sheetDataLoaded = true;
    this.getWorksheetHandler().syncHandler(this.worksheet.nativeElement);
    setTimeout(() => {
      // console.log(sheet.options);

      sheet.options.worksheetId = this.sheetsConfig[tabIndex].sectionId;

      let table: any = document.querySelectorAll(".jexcel_content table")[0];
      var totalWidth: any = Array.from(table.rows[0].cells).reduce(function (
        sum,
        cell: any
      ) {
        return sum + cell.offsetWidth;
      },
      0);

      table.style.width = totalWidth + 12 * table.rows[0].cells.length + "px";
    }, 500);
  }

  getWorksheetHandler(): ExcelDocHandler {
    // if worksheetHandler does not exist Create one
    if (!(this.worksheetHandler instanceof ExcelDocHandler)) {
      this.worksheetHandler = new ExcelDocHandler(true);

      // Add notifierService to handler
      this.worksheetHandler.addCommonServices(
        this.sharedMethodService,
        this.rfpService
      );
    }
    return this.worksheetHandler;
  }

  ngAfterViewInit() {
    // let v = document.getElementById("spreadsheet");
    // this.worksheet.nativeElement = v;
  }

  generateEmptySheet(tabConfig: sheetConfig, data: any) {
    // Get Singleton Doc Instance
    let worksheetHandler = this.getWorksheetHandler();
    let rfpDetails = {};'';
    try{rfpDetails = JSON.parse(localStorage.getItem('rfp_details'));}catch(e){}
    // Generate Sheet Options for new Sheet
    let sheetOptions = worksheetHandler.genSheetOptions(
      tabConfig.sheetName,
      data,
      true,
      this.tabsConfig.questionarie,
      this.sheetsConfig.length,
      this.dataColumns,
      this.payer,
      this.canEdit,
      rfpDetails
    );

    // Add new sheet in view

    let sheet = jexcel(this.worksheet.nativeElement, sheetOptions);

    let totalSheets = this.worksheet.nativeElement.jexcel.length;

    if (totalSheets == 1) {
      this.worksheet.nativeElement.jexcel[0].options.worksheetId =
        tabConfig.sectionId;
    } else {
      setTimeout(() => {
        sheet.options.worksheetId = tabConfig.sectionId;
        // Push tabconfig to sheetsConfig Array
        this.sheetsConfig.push(tabConfig);

        let table: any = document.querySelectorAll(".jexcel_content table")[0];
        var totalWidth: any = Array.from(table.rows[0].cells).reduce(function (
          sum,
          cell: any
        ) {
          return sum + cell.offsetWidth;
        },
        0);
        console.log(totalWidth, 12 * table.rows[0].cells.length);

        table.style.width = totalWidth + 12 * table.rows[0].cells.length + "px";
      }, 1000);
    }
  }

  getWorksheetTabs() {
    localStorage.removeItem("has_required");
    localStorage.removeItem("worksheet_required_filled");
    this.rfpService
      .getWorksheetTabsbyRfpId(this.RfpId)
      .pipe(
        // Initialize empty sheetTabs; returns true in Order to call getSheetDataBySheetId() if dataExist.
        filter((x: ApiResponse) => {
          if (x.code == 200 && x.data.length > 0) {
            let lastTab = x.data[x.data.length - 1];
            this.activeSheetIndex = 0;
            // Initializing Empty Tabs
            var i = 0;
            x.data.forEach((tab) => {
              this.sheetsConfig[i] = { sectionId: tab.id, sheetName: tab.name };
              this.generateEmptySheet(
                { sectionId: tab.id, sheetName: tab.name },
                tab.columns
              );
              i++;
            });
            // Sync DocHandler with new worksheet
            this.getWorksheetHandler().syncHandler(
              this.worksheet.nativeElement
            );
            if (lastTab.id > 0) {
              return true;
            }
          }

          return false;
        }),
        // switchMap will work only if filter returned true; there was atleast one existing sheet
        switchMap((x: ApiResponse) => {
          let lastTabIndex = x.data.length - 1;
          // zipping sheetData of last Sheet, coz, need to load data of active tab. other will be loaded on tabClick
          return zip(
            this.rfpService.getsheetDataBySheetId(
              this.RfpId,
              x.data[lastTabIndex].id
            ),
            of(lastTabIndex)
          );
        })
      )
      .subscribe((res: any[]) => {
        // LastSheetData
        let response: ApiResponse = res[0];

        // LastSheet index
        let lastTabIndex: number = res[1];

        if (response.code == 200) {
          // Loads data into the sheet[tabIndex]
          this.dataColumns = response.data[0];
          setTimeout(() => {
            this.dataColumns.forEach((element, index) => {
              if (element.required == true) {
                localStorage.setItem("has_required", "true");
              }
            });
          }, 300);

          this.updateSheetByTabIndex(response.data[0], lastTabIndex);
        }
      });
  }
  async sendSheet() {
    let isSubmit = false;
    let hasEmptyRequired = false;
    this.getWorksheetHandler().syncHandler(this.worksheet.nativeElement);
    let requiredColumns = [];
    setTimeout((res) => {
      let sheets = this.getWorksheetHandler().getAllSheets();
      this.dataColumns.forEach((element, index) => {
        if (element.required == true) {
          localStorage.setItem("has_required", "true");
          requiredColumns.push(index);
        }
      });
      let dataNeedToValidate = [];
      sheets[0].data.forEach((singleRow, index) => {
        if (singleRow.filter((value) => value !== "").length > 0) {
          dataNeedToValidate.push(singleRow);
        }
      });

      dataNeedToValidate.forEach((v, index) => {
        requiredColumns.forEach((reqIndex, index) => {
          if (v[reqIndex] === "") {
            hasEmptyRequired = true;
          }
        });
      });
      if (hasEmptyRequired) {
        return false;
      }

      from(sheets)
        .pipe(
          tap((sheet) => {}),
          mergeMap(
            (sheet) =>
              this.rfpService.addNewSheet(
                this.RfpId,
                sheet.sheetId,
                sheet.data
              ),
            2
          )
        )
        .subscribe(
          (res: any) => {
            if (res.errors == null) {
              isSubmit = true;
            }
          },
          (err) => {
            this.sharedMethodService.setError(err);
          }
        );
    }, 1000);
    setTimeout(() => {
      if (isSubmit == true) {
        if (requiredColumns.length != 0) {
          localStorage.setItem("worksheet_required_filled", "true");
        }
        this.toastService.success("Worksheet saved successfully.", "", {
          opacity: 1,
        });
        //Swal.fire('Worksheet', 'Worksheet Submitted Successfully.', 'success');
      } else {
        if (hasEmptyRequired) {
          localStorage.setItem("worksheet_required_filled", "false");
          this.toastService.error("Please fill required fields");
        } else {
          this.toastService.error("Some error occured.");
        }
      }
      let table: any = document.querySelectorAll(".jexcel_content table")[0];
      var totalWidth: any = Array.from(table.rows[0].cells).reduce(function (
        sum,
        cell: any
      ) {
        return sum + cell.offsetWidth;
      },
      0);
      console.log(totalWidth, 12 * table.rows[0].cells.length);

      table.style.width = totalWidth + 12 * table.rows[0].cells.length + "px";
    }, 4000);
  }
}
