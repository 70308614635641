import { Component, OnInit } from '@angular/core';
import { RfpService } from '../_services';
import { ApiResponse } from '../_models/response';
import { DatePicker } from '../const';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public rfpsErrorMsg = "";
  public vendorPropsal = [];
  DatePicker = DatePicker;
  public chartLabels: Array<any> = ['In Progress', 'Not Started', 'Complete']
  public chartColors: Array<any> = [
    {
      backgroundColor: [
        'rgb(67, 209, 195)',
        'rgb(67, 209, 195)',
        'rgb(67, 209, 195)',
      ],
      //borderColor: [
      //  'rgba(255,99,132,1)',
      //  'rgba(54, 162, 235, 1)',
      //  'rgba(255, 206, 86, 1)',
      //  'rgba(75, 192, 192, 1)',
      //  'rgba(153, 102, 255, 1)',
      //  'rgba(255, 159, 64, 1)'
      //],
      // borderWidth: 2,
    }
  ];
  public chartDatasets: Array<any> = [
    {
      data: [0, 0, 0]
      //, label: 'My First dataset'
    }
  ];
  public chartOptions = { responsive: true };
  constructor(private rfpService: RfpService) { }

  ngOnInit() {
    this.rfpService.proposals().subscribe((x: ApiResponse) => {
       if (x.code == 200) {
        //['In Progress', 'Not Started', 'Complete']
        let d = x.data[0];
        if (d.length>0) {
          // this.chartDatasets = [{ data: [d.progressPercentage, d.notStartedPercentage, d.completePercentage] }];
          this.vendorPropsal = d;
        }
      } else {
        this.rfpsErrorMsg = x.message;
      }
    }, er => {
      this.rfpsErrorMsg = er;
    })
  }

}
