import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) { }

  verifyVnRegisterUser(id, token) {
    return this.http.put(`${environment.apiUrl}/user/verify/`+id+"/"+token, {});
  }
  getAllUsers() {
    return this.http.get(`${environment.apiUrl}/users`);
  }

  getPublicUser(vendorId) {
    //
    return this.http.get(`${environment.apiUrl}/publicuser/${btoa(vendorId)}`);
  }

  getAllvendors() {
    return this.http.get(`${environment.apiUrl}/vn/vendor/get/users`);
  }

  getUser(userId) {
    return this.http.get(`${environment.apiUrl}/user/${btoa(userId)}`);
  }

  getvendor(vendorId) {
    return this.http.get(`${environment.apiUrl}/vn/vendor/${btoa(vendorId)}`);
  }

  deleteuser(userId) {
    return this.http.get(`${environment.apiUrl}/user/${btoa(userId)}`);
  }

  deletevendor(vendorId) {
    return this.http.delete(`${environment.apiUrl}/vn/vendor/${btoa(vendorId)}`);
  }

  updateCurrentProfile(frm) {
    let setupObj = {
      fname: frm.firstName,
      lname: frm.lastName,
      phone: frm.phoneno
    }
    return this.http.put(`${environment.apiUrl}/profiledetails/update`, setupObj);
  }
  postuser(frm) {
    let setupObj = {
      fname: frm.firstName,
      lname: frm.lastName,
      email: frm.emailid,
      role_id: frm.roles,
      phone: frm.phoneno,
      password: frm.password,
      invitation_message: frm.invitationmessage
    }
    return this.http.post(`${environment.apiUrl}/create/user`, setupObj);
  }

  updateuser(frm, usrId) {
    let setupObj = {
      fname: frm.firstName,
      lname: frm.lastName,
      email: frm.emailid,
      role_id: frm.roles,
      phone: frm.phoneno,
      password: frm.password
    }
    return this.http.put(`${environment.apiUrl}/update/user/${btoa(usrId)}`, setupObj);
  }

  updatevendor(frm, usrId) {
    let setupObj = {
      fname: frm.firstName,
      lname: frm.lastName,
      email: frm.emailid,
      role_id: frm.roles,
      phone: frm.phoneno,
      password: frm.password
    }
    return this.http.put(`${environment.apiUrl}/vn/vendor/${btoa(usrId)}`, setupObj);
  }

  postvendor(frm) {
    let setupObj = {
      fname: frm.firstName,
      lname: frm.lastName,
      email: frm.emailid,
      role_id: frm.roles,
      phone: frm.phoneno,
      password: frm.password,
      invitation_message: frm.invitationmessage
    }
    return this.http.post(`${environment.apiUrl}/vn/vendor`, setupObj);
  }
}

