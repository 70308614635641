import * as jexcel from "jexcel-pro";
import { operation, operationFlags, ExcelSheet } from "./excelSheet";
import { environment } from '../../environments/environment';
export class ExcelDocHandler {
  private sheets: ExcelSheet[] = [];
  doc: any;
  // if true readonly fields will be shows editable, but its readonly state will still be preserved in obj.getColumnOptions(x).options.readOnly = true;
  isEditable: boolean;
  element: any;
  notifierService: any;
  rfpService: any;
  public selectedTabIndx = 0;
  public selectedRow = 0;
  public selectedColumn = 0;
  public selectedCellName = '';

  public updateArr = [];
  public tabMapping = <any>{};
  public excel: any;

  constructor(isEditable) {
    this.isEditable = isEditable;
  }
  getSheetCount() {
    return this.sheets.length;
  }

  addNotifierService(notifierService) {
    this.notifierService = notifierService;
  }

  getAllSheets(): ExcelSheet[] {
    return this.sheets;
  }
  getDocInstance() {
    return this.sheets;
  }

  putChanges(element: any) {
    this.element = element;
    this.doc = element.jexcel;
  }

  syncHandler(element: any) {
    this.element = element;
    this.doc = element.jexcel;
    //setTimeout(() => {
    this.setupSheet();
    //}, 500);
  }

  setupSheet() {

    this.sheets = [];
    let worksheetState: any = this.doc;

    if (worksheetState instanceof Array) {

      for (let i = 0; i < worksheetState.length; i++) {
        let v = new ExcelSheet(this.rfpService, this.notifierService);
        v.data = worksheetState[i].getData();

        v.worksheetName = worksheetState[i].options.worksheetName;
        v.sheetId = worksheetState[i].options.worksheetId;
        // loop over columns and get options and make headers

        for (let j = 0; j < worksheetState[i].colgroup.length; j++) {
          let header = worksheetState[i].getColumnOptions(j);
          header = new ExcelSheetHeader(header.title, header.options.readOnly, header.options.hidden,header.options.required);
          v.columns.push(header);
        }

        this.sheets.push(v);
      }
      // for(let x1 of worksheetState){

      //   let v = new ExcelSheet(this.rfpService, this.notifierService);
      //   v.data = x1.getData();
      //   console.log(x1.getConfig().worksheetName);

      //   v.worksheetName = x1.getConfig().worksheetName;
      //   v.sheetId = x1.getConfig().worksheetId;
      //   // loop over columns and get options and make headers
      //   for(let i = 0; i< x1.colgroup.length; i++){
      //     let header = x1.getColumnOptions(i);
      //     header = new ExcelSheetHeader(header.title, header.options.readOnly, header.options.hidden);
      //     v.columns.push(header);
      //   }
      //   this.sheets.push(v);
      // }
    } else {
      let v = new ExcelSheet(this.rfpService, this.notifierService);
      v.data = worksheetState.getData();
      v.sheetId = worksheetState.options.worksheetId;
      // console.log("Sheet",worksheetState);
      // loop over columns and get options and make headers
      try {
        for (let i = 0; i < worksheetState.colgroup.length; i++) {
          let header = worksheetState.getColumnOptions(i);
          header = new ExcelSheetHeader(header.title, header.options.readOnly, header.options.hidden,header.options.required);
          v.columns.push(header);
        }
      }
      catch { }
      this.sheets.push(v);
    }
  }

  // addSheet(obj) {

  //   let sheetName = "";
  //   if(obj!=null){
  //     sheetName = obj.worksheetName;
  //   }else{
  //     sheetName = prompt("Enter Section Name")
  //   }
  //   console.log(obj);
  //   return this.makeJExcelTabs(sheetName, obj);
  // }

  // makeJExcelTabs(sheets, obj){

  //   let sheetName = sheets;
  //   let sheet = [];
  //   if(obj){

  //     sheet.push(this.genSheetOptions(sheetName, obj, this.isEditable, ));
  //   }else{
  //     sheet.push([this.genSheetOptions(sheetName, null, this.isEditable)]);
  //   }
  //   return sheet;

  // }

  // gets Notifier Service which notify when tab changes
  addCommonServices(notifierService, rfpSetupService) {
    this.notifierService = notifierService;
    this.rfpService = rfpSetupService;
  }

  genSheetOptions(sheetName, obj, isEditable, questionarieDisplayStatus, sheetIndex = null, DynamicData, payer, canEdit, rfpDetails=null) {
    // console.log(obj);
    // return false;
    let rows = [[]];
    let shName = "";
    let cols = [];
    let notifier: any
    if (this.notifierService) {
      notifier = this.notifierService;
    } else {
      console.log("NotifierService was not Assigned");
    }

    // when is already existing sheet
    if (obj != null) {
      cols = obj;
    }
    let worksheetType = rfpDetails.worksheet_type;
    shName = sheetName ? sheetName : 'Section 1';
    var _this = this;
    const columns = cols.map(c => {
      if(worksheetType !== 'custom'){
        if (!canEdit) {
          c.readOnly = true;
        }
        return c.mask ? Object.assign(c, { disabledMaskOnEdition: true }) : c;
      }else{
        return c;
      }
    });
    
    return {
      worksheetName: shName,
      data: rows,
      columns,
      license: environment.jexcelKey,
      updateTable: function (el, cell, x, y, source, value, label) {
        // if(payer=="is"){
        //   cell.classList.add('readonly');
        // }
        // else{
        let falsePosition = [];
        for (let i = 0; i < DynamicData.columns.length; i++) {
          if (DynamicData.columns[i].readOnly == true) {
            falsePosition.push(i);
          }
        }
        if(worksheetType !== 'custom'){
          setTimeout(() => {
            for (let j = 0; j < falsePosition.length; j++) {
              if (x == falsePosition[j]) {
                cell.classList.add('readonly');
              }
            }
          }, 1000);
        }
        
        //}
      },
      tabs: false,
      minDimensions: [6, 10],
      onopenworksheet: function (element, instance, worksheetNumber) {
        notifier.setWorksheetSelectedTabIndex(worksheetNumber);
      },
      contextMenu: function (obj, x, y, e) {
        return false;
        /* let __this = this;

        console.log(obj.options.worksheetName);
        var items = [];
        if(x<4){
          return false;
        }
        if(y==null && x==null){
          items.push({
            title:"Rename Sheet",
            onclick:function() {
              notifier.setWorksheetSelectedTabIndex(sheetIndex);
              __this.onopenworksheet(null, _this.doc[sheetIndex], sheetIndex);
              sheetName = prompt("Enter New Sheet Name");
              if(sheetName && sheetName!=""){
                let sheetId = obj.options.worksheetId;
                if(sheetId){
                  let sheet: ExcelSheet = _this.sheets[sheetId];

                  sheet.addOperation({opPayload: {sheet_title: sheetName }, opFlag:operationFlags.SectionTitleRename});

                  // Fire Event from this Worksheet Object to add Event
                  obj.renameWorksheet(obj.getWorksheet(), sheetName);
                }
              }
            }
          });
        }
        else if (y == null && x!=null) {
          // Insert a new column
          if (obj.options.allowInsertColumn == true) {
            items.push({
              title:obj.options.text.insertANewColumnBefore,
              onclick:function() {
                obj.insertColumn(1, parseInt(x), 1);
              }
            });
          }

          if (obj.options.allowInsertColumn == true) {
            items.push({
              title:obj.options.text.insertANewColumnAfter,
              onclick:function() {
                obj.insertColumn(1, parseInt(x), 0);
              }
            });
          }

          // Delete a column
          if (obj.options.allowDeleteColumn == true) {
            items.push({
              title:obj.options.text.deleteSelectedColumns,
              onclick:function() {
                obj.deleteColumn(obj.getSelectedColumns().length ? undefined : parseInt(x));
              }
            });
          }

          // Rename column
          if (obj.options.allowRenameColumn == true) {
            items.push({
              title:obj.options.text.renameThisColumn,
              onclick:function() {
                let sheetId = obj.options.worksheetId;
                if(sheetId){
                  let sheet: ExcelSheet = _this.sheets[sheetId];
                  sheet.addOperation({opPayload: {sheet_title: sheetName }, opFlag:operationFlags.SectionTitleRename});
                  obj.setHeader(x);
              }
            }});
          }

          // Sorting
          if (obj.options.columnSorting == true) {
            // Line
            items.push({ type:'line' });

            items.push({
              title:obj.options.text.orderAscending,
              onclick:function() {
                obj.orderBy(x, 0);
              }
            });
            items.push({
              title:obj.options.text.orderDescending,
              onclick:function() {
                obj.orderBy(x, 1);
              }
            });
          }
        } else if(y!=null) {
          // Insert new row
          if (obj.options.allowInsertRow == true) {
            items.push({
              title:obj.options.text.insertANewRowBefore,
              onclick:function() {
                obj.insertRow(1, parseInt(y), 1);
              }
            });

            items.push({
              title:obj.options.text.insertANewRowAfter,
              onclick:function() {
                obj.insertRow(1, parseInt(y));
              }
            });
          }
          let az = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

          items.push({
            title:"Set Column Readonly",
            onclick:function() {
              obj.getColumnOptions(x).options.readOnly = true;
              let rx = obj.rows.length;

                for(let i = 1; i<=rx; i++){
                  console.log(az[x]+i);
                   obj.setStyle(az[x]+i,'background-color','#ddd');
                 }
            }
          });

          items.push({
            title:"Set Column Hidden",
            onclick:function() {
              obj.getColumnOptions(x).options.hidden = true;
               let rx = obj.rows.length;

                for(let i = 1; i<=rx; i++){
                  console.log(az[x]+i);
                   obj.setStyle(az[x]+i,'background-color','#e8e8e86b');
                 }
            }
          });

          if (obj.options.allowDeleteRow == true) {
            items.push({
              title:obj.options.text.deleteSelectedRows,
              onclick:function() {
                obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
              }
            });
          }

          if (x) {
            if (obj.options.allowComments == true) {
              items.push({ type:'line' });

              var title = obj.obj.getCellFromCoords(x, y).getAttribute('title') || '';

              items.push({
                title: title ? obj.options.text.editComments : obj.options.text.addComments,
                onclick:function() {
                  obj.setComments([ x, y ], prompt(obj.options.text.comments, title));
                }
              });

              if (title) {
                items.push({
                  title:obj.options.text.clearComments,
                  onclick:function() {
                    obj.setComments([ x, y ], '');
                  }
                });
              }
            }
          }
        }

        // Line
        items.push({ type:'line' });

        // Save
        if (obj.options.allowExport) {
          items.push({
            title: obj.options.text.saveAs,
            shortcut: 'Ctrl + S',
            onclick: function () {
              obj.download();
            }
          });
        }
        // About
        if (obj.options.about) {
          items.push({
            title:obj.options.text.about,
            onclick:function() {
              alert(obj.options.about);
            }
          });
        }
        return items; */
      }

    }
  }

  genSheetOptions1(sheetName, obj, isEditable, sheetIndex = null) {

    let $this = this;

    let rows = [[]];
    let shName = "";
    let cols = [];
    let notifier: any
    if (this.notifierService) {
      notifier = this.notifierService;
    } else {
      console.log("NotifierService was not Assigned");
    }
    if (obj != null) {

      rows = obj.data.length > 0 ? obj.data : [[]];
      cols = obj.columns.length > 0 ? obj.columns : [];
      if (obj.columns.length > 0) {
        if (isEditable) {
          cols.forEach(element => {
            if (element.readOnly) {
              element.readOnly = false;
              // obj.getColumnOptions(x).options.readOnly = true;
            }
            if (element.hidden) {
              element.hidden = false;
            }
          });
        }
      }
    }

    shName = sheetName ? sheetName : 'Section 1';
    const columns = cols.map(c => {
      return c.mask ? Object.assign(c, { disabledMaskOnEdition: true }) : c;
    });
    return {
      worksheetName: shName,
      data: rows,
      columns,
      tabs: false,
      minDimensions: [6, 10],
      license: environment.jexcelKey,
      onchange: function (instance, cell, x, y, value) {
        console.log('inside onchange excelData');
        console.log('cell', cell);
        console.log('x column', x);
        console.log('y row', y);
        console.log('value', value);

        $this.selectedCellName = jexcel.getColumnNameFromId([x, y]);
        console.log('cellName', $this.selectedCellName);
        $this.selectedRow = y;
        $this.selectedColumn = x;

        $this.setUpdateArray();
      },
      onevent: function () {
        console.log('inside on events', arguments);
        // console.log('arguments[2].getSelected()', arguments[1].getSelected());
        if (arguments[0] == 'onload') {
          // console.log('yes arguments[0] == onload');
          // console.log('arguments[2].getRowId()', arguments[2].getRowId());
          // console.log('arguments[2].options.getRowId', arguments[2].options.getRowId);
        } else {
          // console.log('else', arguments[0]);
        }
      },
      onopenworksheet: function (element, instance, worksheetNumber) {
        console.log('worksheetNumber this.selectedTabIndx', worksheetNumber);
        this.selectedTabIndx = worksheetNumber;
        notifier.setWorksheetSelectedTabIndex(worksheetNumber);
      },
      onbeforepaste: function (obj1, dataPasted, x1, y1) {
        let r = confirm("Do you want to treat first row as header ?");
        if (r == true) {
          let headers = [];
          var temp = "";
          var k = 0;
          for (let s of dataPasted) {
            k++;
            if (s == '\t') {
              if (temp != "") {
                headers.push(temp);
                temp = "";
              }
            } else if (s == '\n') {
              console.log(headers);
              break;
            }
            console.log(s);
            temp += s;
          }

          var i = 0;
          for (let header of headers) {
            this.setHeader(i, header);
            i++;
          }
          dataPasted = dataPasted.substring(k, dataPasted.length);
          console.log(dataPasted);
          return dataPasted;
        } else {

        }

      },
      contextMenu: function (obj, x, y, e) {
        console.log(obj);
        var items = [];

        if (y == null && x == null) {
          items.push({
            title: "Rename Sheet",
            onclick: function () {
              sheetName = prompt("Enter New Sheet Name");
              obj.renameWorksheet(obj.getWorksheet(), sheetName);
            }
          });
        }
        else if (y == null && x != null) {
          // Insert a new column
          if (obj.options.allowInsertColumn == true) {
            items.push({
              title: obj.options.text.insertANewColumnBefore,
              onclick: function () {
                obj.insertColumn(1, parseInt(x), 1);
              }
            });
          }

          if (obj.options.allowInsertColumn == true) {
            items.push({
              title: obj.options.text.insertANewColumnAfter,
              onclick: function () {
                obj.insertColumn(1, parseInt(x), 0);
              }
            });
          }

          // Delete a column
          if (obj.options.allowDeleteColumn == true) {
            items.push({
              title: obj.options.text.deleteSelectedColumns,
              onclick: function () {
                obj.deleteColumn(obj.getSelectedColumns().length ? undefined : parseInt(x));
              }
            });
          }

          // Rename column
          if (obj.options.allowRenameColumn == true) {
            items.push({
              title: obj.options.text.renameThisColumn,
              onclick: function () {
                obj.setHeader(x);
              }
            });
          }

          // Sorting
          if (obj.options.columnSorting == true) {
            // Line
            items.push({ type: 'line' });

            items.push({
              title: obj.options.text.orderAscending,
              onclick: function () {
                obj.orderBy(x, 0);
              }
            });
            items.push({
              title: obj.options.text.orderDescending,
              onclick: function () {
                obj.orderBy(x, 1);
              }
            });
          }
        } else if (y != null) {
          // Insert new row
          if (obj.options.allowInsertRow == true) {
            items.push({
              title: obj.options.text.insertANewRowBefore,
              onclick: function () {
                obj.insertRow(1, parseInt(y), 1);
              }
            });

            items.push({
              title: obj.options.text.insertANewRowAfter,
              onclick: function () {
                obj.insertRow(1, parseInt(y));
              }
            });
          }
          let az = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

          items.push({
            title: "Set Column Readonly",
            onclick: function () {
              obj.getColumnOptions(x).options.readOnly = true;
              let rx = obj.rows.length;

              for (let i = 1; i <= rx; i++) {
                console.log(az[x] + i);
                obj.setStyle(az[x] + i, 'background-color', '#ddd');
              }
            }
          });

          items.push({
            title: "Set Column Hidden",
            onclick: function () {
              obj.getColumnOptions(x).options.hidden = true;
              let rx = obj.rows.length;

              for (let i = 1; i <= rx; i++) {
                console.log(az[x] + i);
                obj.setStyle(az[x] + i, 'background-color', '#e8e8e86b');
              }
            }
          });

          if (obj.options.allowDeleteRow == true) {
            items.push({
              title: obj.options.text.deleteSelectedRows,
              onclick: function () {
                obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
              }
            });
          }

          if (x) {
            if (obj.options.allowComments == true) {
              items.push({ type: 'line' });

              var title = obj.obj.getCellFromCoords(x, y).getAttribute('title') || '';

              items.push({
                title: title ? obj.options.text.editComments : obj.options.text.addComments,
                onclick: function () {
                  obj.setComments([x, y], prompt(obj.options.text.comments, title));
                }
              });

              if (title) {
                items.push({
                  title: obj.options.text.clearComments,
                  onclick: function () {
                    obj.setComments([x, y], '');
                  }
                });
              }
            }
          }
        }

        // Line
        items.push({ type: 'line' });

        // Save
        if (obj.options.allowExport) {
          items.push({
            title: obj.options.text.saveAs,
            shortcut: 'Ctrl + S',
            onclick: function () {
              obj.download();
            }
          });
        }
        // About
        if (obj.options.about) {
          items.push({
            title: obj.options.text.about,
            onclick: function () {
              alert(obj.options.about);
            }
          });
        }

        return items;
      }
    }
  }

  async getSelectedTabIndex() {
    return this.selectedTabIndx;
  }

  async getSelectedRow() {
    return this.selectedRow;
  }

  async getSelectedColumn() {
    return this.selectedColumn;
  }

  async getSelectedCellName() {
    return this.selectedCellName;
  }

  async setTabMapping(in_data) {
    return this.tabMapping = in_data;
  }

  async setExcel(in_excel) {
    return this.excel = in_excel;
  }

  async setUpdateArray() {

    this.setupSheet();
    console.log('inside setUpdateArray');

    let exceldata = this.getDocInstance();
    let activeSheetIndex = await this.getSelectedTabIndex();
    let selectedRow = await this.getSelectedRow();
    let sheetData = exceldata[activeSheetIndex].data;
    let tabId = this.tabMapping[activeSheetIndex].id;
    let row_id = this.excel.getRowId(this.selectedRow);

    console.log('inside setUpdateArray this.excel', this.excel);
    console.log('inside setUpdateArray exceldata', exceldata);
    console.log('inside setUpdateArray activeSheetIndex', activeSheetIndex);
    console.log('inside setUpdateArray sheetData', sheetData);
    console.log('inside setUpdateArray selectedRow', selectedRow);
    console.log('inside setUpdateArray tabId', tabId);
    console.log('inside setUpdateArray row_id', row_id);

    let row_data = sheetData[this.selectedRow];
    console.log('row_data', row_data);
    this.updateArr.push({
      row_id: row_id,
      row_data: row_data
    });
    return this.updateArr;
  }

  async getUpdateArray() {
    return this.updateArr;
  }
}

// export class ExcelSheet {
//   worksheetName:string;
//   criteriaId: number = -1;
//   data: [[]];
//   columns:ExcelSheetHeader[] = [];
// }

export class ExcelSheetHeader {
  name: string;
  readOnly: boolean = false;
  hidden: boolean = false;
  required: boolean = false;
  constructor(name, readOnly, hidden, required) {
    this.name = name;
    this.readOnly = readOnly;
    this.hidden = hidden;
    this.required = required;
  }
}
