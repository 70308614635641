import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthenticationService } from '../../_services';
import { FormBuilder, Validators } from '@angular/forms';
import { RoleService } from '../../_services/role.service';
import { ApiResponse } from '../../_models/response';
import { debug } from 'util';
import { environment } from '../../../environments/environment';
import {Router} from '@angular/router';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  RoutePrefix = "";
  submitted = false;
  viewPasswordIcon:any = 'eye-slash';
  passwordType:any = 'password';
  loading = false;
  returnUrl: string;
  error = '';
  isSuccess = false;
  successMsg = "";
  serverError = "";
  isAccepted = true;
  RoleOptions: Array<any>;
  paymentTermsLink = "";
  privacyLink = "";
  hasNumber:any = false;
  hasUpper:any = false;
  hasLower:any = false;
  hasNumeric:any = false;
  hasSymbol:any = false;
  hasMinChars:any = false;
  hasMaxChars:any = false;
  hasError: any = true;
  passwordText:any = '';
  showHideValidatorPopup: any = false;
  constructor(
    private formBuilder: FormBuilder,
    private userService: AuthenticationService,
    private router: Router
  ) {
    this.userService.getPaymentTerms().subscribe((res: any) => {
      if (res.code == 200) {
        this.paymentTermsLink = res.data[0].terms;
        this.privacyLink = res.data[0].privacy;
      }
    });
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      Firstname: new FormControl('', Validators.required),
      Lastname: new FormControl(''),
      Email: new FormControl('', Validators.required),
      Password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      Companyname: new FormControl('', Validators.required),
      //RoleId: new FormControl('', Validators.required)
    }, {
      validator: this.mustMatch('Password', 'confirmPassword')
    });
  }
  mustMatch(controlName: string, matchingControlName: string) {

    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
        this.error = "Passwords Does not Match";
      } else {
        this.error = "";
        matchingControl.setErrors(null);
      }
    }
  }
  get r() { return this.registerForm.controls; }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    let u1 = {
      fname: this.r.Firstname.value,
      lname: this.r.Lastname.value,
      email: this.r.Email.value,
      password: this.r.Password.value,
      company_name: this.r.Companyname.value,
      type: "vn"
    };

    this.userService.register(u1).subscribe((res: any) => {
      if (res.code == 200) {
        this.successMsg = res.message;
        this.isSuccess = true;
      }
    }, (err) => {
      this.loading = false;
      this.error = err;
    });
  }

  checkIFAccepted(event) {
    if (event.checked) {
      this.isAccepted = false;
    }
    else {
      this.isAccepted = true;
    }
  }

  viewPassword(){
    this.viewPasswordIcon = (this.viewPasswordIcon != 'eye' )?'eye': 'eye-slash';
    this.passwordType =  (this.viewPasswordIcon == 'eye' )?'text': 'password';
  }


  validatePassword(e){
    let value = e.target.value;
    this.passwordText = value;
    console.log(value.length);
    (value.toString().length > 0 && value.toString().length <= 20)? this.hasMaxChars = true : this.hasMaxChars = false;
    (value.length >= 8)? this.hasMinChars = true : this.hasMinChars = false;
    this.hasNumeric = /\d/.test(value);
    this.hasUpper = /[A-Z]/.test(value);
    this.hasLower = /[a-z]/.test(value);
    let format = /[!@#^&*()+_,.{}?-]/;
    this.hasSymbol = format.test(value);
    if(!this.hasMaxChars || !this.hasNumeric || !this.hasUpper || !this.hasLower || !this.hasSymbol || !this.hasMinChars){
      this.hasError = true;
    }else{
      this.hasError = false;
    }
  }

  isConfirmedPassword(e){
    if(this.passwordText != e.target.value){
      this.hasError = true;
    }else{
      this.hasError = false;
    }
  }

  showHideValidator(showHide,e){
    if(showHide == 'show'){
      this.showHideValidatorPopup = true;
    }else{
      this.showHideValidatorPopup = false;
    }
    this.validatePassword(e);
  }

}
