import { Component, Input, OnInit, Output, EventEmitter, ViewChild,  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { MdbTableDirective, MdbTablePaginationComponent, MdbTableService } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';
import { User } from 'src/app/_models';
import { ApiResponse } from 'src/app/_models/response';
import { DatePicker } from '../../../const';
import { Rfp, Section } from 'src/app/_models/rfp';
import { VenderService , DashboardStoreService, CommonService, AuthenticationService } from 'src/app/_services';
import { UpperCasePipe } from '@angular/common';

@Component({
  selector: 'app-add-delegate',
  templateUrl: './add-delegate.component.html',
  styleUrls: ['./add-delegate.component.scss']
})
export class AddDelegateComponent implements OnInit {
  @Output('showConfirmPaymentBox') showConfirmPaymentBoxEvent = new EventEmitter();
  // @ViewChild('tablePagination', { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('tablePagination',{ static: true }) mdbTablePagination: MdbTablePaginationComponent;
   @Input('rfp_id') RfpId: string;
  @Input('rfp_status') rfpStatus: string;
  @Input('rfp_sub_status') rfpSubStatus: string;

  public DatePicker = DatePicker;
  public rfp$: Observable<Rfp>;
  public assignedDeligates = [];
  Roles: Array<any> = [
    {
      value: 3, label: 'Contributor',
    },
    {
      value: 4, label: 'Viewer',
    }
  ];
  rfp: Rfp;
  delegateAddFormError = "";
  existinviteWarning = [];
  addDelegateFormGroup: FormGroup;
  tabsConfig : any;
  constructor(
    public dashboardStoreService: DashboardStoreService,
    private venderService: VenderService,
    private router: Router,
    private commonservice: CommonService,
    private auth: AuthenticationService,
    ) { }

    ngOnInit() {
      this.addDelegateFormGroup = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        emailid: new FormControl('', [Validators.required]),
        invitationmessage: new FormControl(''),
         roles: new FormControl(''),
      });

      this.dashboardStoreService.getTabsConfig().subscribe((res:any)=>{
        this.tabsConfig = res;
      });

      this.dashboardStoreService.getRfpDetails().subscribe((res:any)=>{
        this.rfp = res;
      });

      this.getRfxAssignedDeligates(this.RfpId);
    }
    ngAfterViewInit() {

    //   this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);
    //   this.mdbTablePagination.calculateFirstItemIndex();
    //   this.mdbTablePagination.calculateLastItemIndex();
    }
    goToCreateDeligate() {
      this.router.navigate([`/rfxId/${this.RfpId}/deligates-add-edit`]);
    }

    renameThisFunction(){

      return (this.rfpStatus == 'accepted' &&  this.rfpSubStatus == 'null' ) && (this.rfp.rolename != 'VendorUser');
    }

    getRfxAssignedDeligates(rfxId) {
      this.venderService.getAssignVendorsByRfx( rfxId ).subscribe( (result : any) => {
        if( result.code == 200 ) {

          //   alert( result.message );
          if( result.data[0].length > 0 ) {

            this.assignedDeligates = result.data[0];
            // this.mdbTable.setDataSource(this.assignedDeligates);
            // this.assignedDeligates = this.mdbTable.getDataSource();
            // this.previous = this.mdbTable.getDataSource();
          } else {
            this.assignedDeligates = [];
          }

        } else {
          this.assignedDeligates = [];
        }
      } );
    }

    updateDeligateRole( rfx_id, delegate_id, event ) {
      console.log('event.target.value', event.target.value);
      let rfx_role = event.target.value;
      this.venderService.updateDeligateRole( rfx_id, delegate_id, rfx_role ).subscribe( (result : any) => {
          console.log('result', result);

          if( result.code == 200 ) {

              if( result.errors > 0 ) {

                  // alert(result.message);
                  // Swal.fire({});
                  Swal.fire('Oops...', 'Something went wrong!', 'error');
              } else {
                  // alert('Role updated succssfully!');
                  Swal.fire('Success', 'Role updated succssfully!', 'success');
              }
          } else {
              Swal.fire('Oops...', 'Something went wrong!', 'error');
          }
        } );
    }

    deleteAssignedDeligates( rfx_id, delegate_id ) {

      console.log( 'rfx_id', rfx_id );
      console.log( 'delegate_id', delegate_id );

      if( confirm('Are you sure to un-assign this delegate?') ) {

          this.venderService.unAssignVendorsToRfx( rfx_id, delegate_id ).subscribe( (result : any) => {
              console.log('result', result);

              if( result.code == 200 ) {

                  if( result.errors > 0 ) {

                      // alert( result.message );
                      Swal.fire('Oops...', result.message, 'error');

                  } else {
                      // alert( result.message );
                      Swal.fire('Success', result.message, 'success');
                  }
                  this.getRfxAssignedDeligates( rfx_id );
              } else {
                  // alert( result.message );
                  Swal.fire('Oops...', result.message, 'error');
              }
            } );
      } else {
          console.log('User selected cancel.');
      }
  }

  reAssignedDeligates( rfx_id, delegate_id ) {

      console.log( 'rfx_id', rfx_id );
      console.log( 'delegate_id', delegate_id );

      if( confirm('Are you sure to re-assign this delegate?') ) {

          this.venderService.reAssignVendorsToRfx( rfx_id, delegate_id ).subscribe( (result : any) => {
              console.log('result', result);

              if( result.code == 200 ) {

                  if( result.errors > 0 ) {

                      // alert( result.message );
                      Swal.fire('Oops...', result.message, 'error');
                  } else {
                      // alert( result.message );
                      Swal.fire('Success', result.message, 'success')
                  }
                  this.getRfxAssignedDeligates( rfx_id );
              } else {
                  // alert( result.message );
                  Swal.fire('Oops...', result.message, 'error');
              }
            } );
      } else {
          console.log('User selected cancel.');
      }
  }
  showConfirmPaymentBox1(){
     this.showConfirmPaymentBoxEvent.emit("");
  }

  ShowDelegateAddModal(m){

    this.existinviteWarning =[];
    this.delegateAddFormError = "";
    setTimeout(() => {
      this.commonservice.SetFormValue(this.addDelegateFormGroup, "firstName", "");
      this.commonservice.SetFormValue(this.addDelegateFormGroup, "lastName", "");
      this.commonservice.SetFormValue(this.addDelegateFormGroup, "emailid", "");
      this.commonservice.SetFormValue(this.addDelegateFormGroup, "roles", "");
      // this.commonservice.SetFormValue(this.addDelegateFormGroup, "invitationmessage", "");
    }, 200)
    let user = this.auth.currentUserValue;
     console.log(user);
    let name = user.fName;
    if (user.lName) {
        name += " " + user.lName;
    }
      let msg = `You are invited by ${name} (`+ user.comp_name.toUpperCase() +`)`;
    console.log(this.rfp);
    let deadline = '';
    if(this.rfp.deadlineForQuestions){
      deadline = DatePicker.ApiToUiFormat(this.rfp.deadlineForQuestions).Date;
    }
    let issuerCompName = '';
    if(this.rfp.company != null){
      issuerCompName = this.rfp.company.name.toUpperCase();
    }
    // let role= this.addDelegateFormGroup.get('roles').value;
    msg += ` to be a participate in an RFx by ` + issuerCompName + ' which ends at '+deadline;
    this.commonservice.SetFormValue(this.addDelegateFormGroup, "invitationmessage", msg);
    m.show();

}
CancelDelegate(m){
    m.hide();
}

saveDelegate(m) {
    this.existinviteWarning = [];
    // console.log(this.addSectionFormGroup.controls.SectionWriter.value);
    // this.sharedMethodService.setError(undefined);
    // this.sectionAddFormError = "";
    this.addDelegateFormGroup.value.emailid = this.addDelegateFormGroup.value.emailid.toLowerCase();
    if (this.addDelegateFormGroup.valid) {

      this.venderService.AddNewDeligate(this.addDelegateFormGroup.value,this.RfpId
        ).subscribe((s: ApiResponse) => {

          if (s.code == 200) {
            if(s.data.invite =='ExistInvite'){

                let msg = s.message;
                let data  : any = s.data;
                data.force = 'yes';
                this.existinviteWarning = data;

                this.delegateAddFormError = msg;
                // this.showConfirmInviteForDelegate(m,msg,data);
            }else{

                m.hide();
                this.getRfxAssignedDeligates( this.RfpId );
            }
          }
          else {

            this.delegateAddFormError = s.message;
            // this.sharedMethodService.setError(s.message);
          }
        }, err => {

          // this.sharedMethodService.setError(err);
          this.delegateAddFormError = err;
        })
    } else {
        this.commonservice.MarkAllAllCtrlDirty(this.addDelegateFormGroup);
    }
}

  mergeDelegate(m,frmdata){

    let frmobj = {
        firstName: frmdata.contact_fname,
        lastName: frmdata.contact_lname,
        emailid: frmdata.email,
        invitationmessage: frmdata.invitation_message,
        force : frmdata.force
    }
    this.venderService.AddNewDeligate(frmobj,this.RfpId
        ).subscribe((s: ApiResponse) => {
        if(s.code == 200){
            m.hide();
            this.getRfxAssignedDeligates( this.RfpId );
        }
    }, err => {
          // this.sharedMethodService.setError(err);
        this.delegateAddFormError = err;
    })
  }
}
