import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { environment } from '../../environments/environment'; 

@Injectable({ providedIn: 'root' })
export class CompaniesService {
  constructor(private http: HttpClient) { }

  getCompany(loggedinUserId) { 
    return this.http.get(`${environment.apiUrl}/vn/vendor/${btoa(loggedinUserId)}`);
  } 
  updateCompanyProfile(frm) {
    let setupObj = {
      name : frm.companyname,
      description: frm.description,
      address: frm.address,
      pincode: frm.pincode,
      phone: frm.phoneno,
      website: frm.websites,
      companySize: frm.companysize
    } 
    return this.http.put(`${environment.apiUrl}/vn/vendor/companydetails/update`, setupObj);   
  }
  /**
   * Status can be - pending | accepted | rejected
   * @param company_id
   * @param status
   */
  getInvitedVendorByStatus(company_id, status) {
    return this.http.get(`${environment.apiUrl}/companies/rfp/vendor/status/${btoa(company_id)}?status=${status}`)
  }

}
