import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { User, GerRfpParam } from '../_models';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { Rfp } from '../_models/rfp';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class RfpService {
  constructor(private http: HttpClient) { }

  ///vendor/rfps?status=accepted&substatus=progress&params={"sortby":"latest","timeline":"any","budget":"any","rfp_name":"rfp_to_search"}
  /**
   * If status is accepted then the substatus field will be use.
   * @param status -  'pending','accepted','rejected'
   * @param substatus - 'progress','completed','sent'
   * @param params - params={"sortby":"latest","timeline":"any","budget":"any","rfp_name":"rfp_to_search"}
   */
  get(status: string, substatus: string = "", params: GerRfpParam = null) {
    var url = `${environment.apiUrl}/vendor/search/rfps?status=${status}`;
    if (substatus) {
      url += `&substatus=${substatus}`;
    }
    if (params) {
      //
      let p = "";
      if (params.sortby) {
        p += `"sortby":"${params.sortby}"`;
      }
      if (params.timeline) {
        if (p) {
          p += ",";
        }
        p += `"timeline":"${params.timeline}"`;
      }
      if (params.budget) {
        if (p) {
          p += ",";
        }
        p += `"budget":"${params.budget}"`;
      }
      if (params.rfp_name) {
        if (p) {
          p += ",";
        }
        p += `"rfp_name":"${params.rfp_name}"`;
      }
      if (p) {
        p = `&params={${p}}`;
        url += p;
      }
    }
    return this.http.get(url);
  }

  chart(rfp_id) {
    return this.http.get(`${environment.apiUrl}/vn/vendor/rfp/${btoa(rfp_id)}/chart`);
  }
  getStatus(rfp_id, ven_comp_id) {
    // alert(ven_comp_id);
    return this.http.get(`${environment.apiUrl}/vn/vendor/${btoa(ven_comp_id)}/invites/rfp/${btoa(rfp_id)}/status`);
  }
  proposals() {
    return this.http.get(`${environment.apiUrl}/vn/vendor/proposals`);
  }
  background(rfp_id) {
    return this.http.get(`${environment.apiUrl}/${btoa(rfp_id)}/background`);
  }
  appendix(rfp_id) {
    //n/vendor/{rfp_id}/appendix
    return this.http.get(`${environment.apiUrl}/vn/vendor/${btoa(rfp_id)}/appendix`);
  }
  section(rfp_id) {
    return this.http.get(`${environment.apiUrl}/vn/vendor/sections/${btoa(rfp_id)}`);
  }
  question(section_id) {
    //vn/vendor/questions/section/{section_id}
    return this.http.get(`${environment.apiUrl}/vn/vendor/questions/${btoa(section_id)}`);
  }
  questionWithAnswer(rfp_id) {
    return this.http.get(`${environment.apiUrl}/vn/vendor/getQuestionWithAnswers/rfp/${btoa(rfp_id)}`);
  }
  saveAnswer(rfp_id, section_id, que_id, answer, option, hasAttachment = false) {
    let setupObj = {
      rfp_id: rfp_id,
      section_id: section_id,
      question_id: que_id,
      option: option,
      answer: answer
    }
    if(hasAttachment){
      setupObj['attachment'] = true;
    }

    return this.http.post(`${environment.apiUrl}/vn/vendor/saveanswer`, setupObj);
  }
  checkSectionComplete(rfpId, sectionId) {
    return this.http.get(`${environment.apiUrl}/vn/vendor/checkSingleSectionCompleteStatus/rfp/${btoa(rfpId)}/section/${btoa(sectionId)}`);
    // vn/vendor/checkSingleSectionCompleteStatus/rfp/{{rfp_id}}/section/{{section_id}}
  }
  acceptRfp(rfp_id, status) {
    let setupObj = {
      status: status
    }
    return this.http.put(`${environment.apiUrl}/vn/vendor/respondRfp/${btoa(rfp_id)}`, setupObj);
  }
  acceptdelegateRfp(rfp_id, status, type) {
    let setupObj = {
      status: status,
      type: type
    }
    return this.http.put(`${environment.apiUrl}/vn/vendor/responddelegateRfp/${btoa(rfp_id)}`, setupObj);
  }
  markAsComplete(section_Id) {
    return this.http.put(`${environment.apiUrl}/vn/vendor/markcompletesection/${btoa(section_Id)}`, {});
  }
  markAsInComplete(section_id) {
    return this.http.put(`${environment.apiUrl}/vn/vendor/markincompletesection/${btoa(section_id)}`, {});
  }
  reviewRfpCompletion(rfp_id) {
    return this.http.get(`${environment.apiUrl}/vn/reviewrfpcompletion/${btoa(rfp_id)}`);
  }
  makePayment(rfp_id, token, frm) {
    let payObj = {
      name: frm.name,
      email: frm.email,
      address: frm.address,
      city: frm.city,
      state: frm.state,
      postal_code: frm.postal_code,
      country: frm.country,
      last_four: token.card.last4

    }
    let tokenid = token.id;
    return this.http.post(`${environment.apiUrl}/vn/rfp/charge/${btoa(rfp_id)}/${tokenid}`, payObj);
  }

  getStripeSetting() {
    return this.http.get(`${environment.apiUrl}/stripe/setting`);
  }


  submitAllSections(rfp_id) {

    return this.http.put(`${environment.apiUrl}/vn/vendor/markcompletrfp/${btoa(rfp_id)}`, {});
  }

  rfpDetail(rfp_id) {
    return this.http.get(`${environment.apiUrl}/${btoa(rfp_id)}/details`);
  }
  getActivity(rfp_id) {
    return this.http.get(`${environment.apiUrl}/vn/vendor/rfp/${btoa(rfp_id)}/activity`);
  }

  // getAllSheetNames()/
  getWorksheetTabsbyRfpId(rfp_id) {
    return this.http.get(`${environment.apiUrl}/vn/vendor/rfx/${btoa(rfp_id)}/worksheet/tabs`);
  }

  // getsheetDataBySheetId()GET /rfp/{rfp_id}/worksheet/tab/{tab_id}
  getsheetDataBySheetId(rfp_id, tab_id) {
    return this.http.get(`${environment.apiUrl}/vn/vendor/rfx/${btoa(rfp_id)}/worksheet/tab/${btoa(tab_id)}`);
  }

  addNewSheet(rfp_id, tab_id, param) {
    return this.http.post(`${environment.apiUrl}/vn/vendor/rfp/${btoa(rfp_id)}/worksheet/tab/${btoa(tab_id)} `, { data: param });
  }

  updateSheet(rfp_id, tab_id, param) {
    // let data= [];
    // param.forEach(element => {
    //     data.push({
    //         row_id : element.row_id,
    //         row_data:element
    //     });
    // });
    return this.http.put(`${environment.apiUrl}/vn/vendor/rfp/${btoa(rfp_id)}/worksheet/tab/${btoa(tab_id)} `, { data: param });
  }

  storeSheetOpFlags(a, b) {
    return of(1);
  }

  getAssignVendorsByRfx(rfx_id) {
    return this.http.get(`${environment.apiUrl}/vn/vendor/rfx/${btoa(rfx_id)}/delegate/assigned`);
  }

  postAnswerAttachment(rfpId, answer_id, file) {
    let frm = new FormData();
    frm.append("attachment", file);
    frm.append("rfp_id", rfpId);
    frm.append("answer_id", answer_id);
    return this.http.post(`${environment.apiUrl}/vn/vendor/answer/attachment`, frm);
  }

  answerAttachmentDelete(rfpId, answer_id, id) {
    return this.http.delete(`${environment.apiUrl}/vn/vendor/rfp/${btoa(rfpId)}/answer/${btoa(answer_id)}/attachment/${btoa(id)}`);
  }
  getRfxCounts() {
    return this.http.get(`${environment.apiUrl}/vn/vendor/rfxcount`);
  }

  getSetupFiles(rfpId) {
    return this.http.get(`${environment.apiUrl}/vn/vendor/rfx/${btoa(rfpId)}/attachment`);
  }

  rfpDetailObservable(rfp_id): Observable<any> {
    return this.rfpDetail(rfp_id).pipe(
      switchMap((res: any) => {
        return this.getActivity(rfp_id).pipe(
          map((res1: any) => {
            if (res1.code == 200) {
              let rfp: Rfp = res.data.rfp;
              rfp.payment_message = res.data.payment_message;
              rfp.activities = res1.data[0];
              return rfp;
            }
          }),
          catchError((e) => {
            return of(res.data.rfp);
          })
        )
      }),
      switchMap((rfp: any) => {
        return this.chart(rfp_id).pipe(
          map((progressState: any) => {
            if (progressState.code == 200) {
              var progressState = progressState.data[0];
              rfp.ProgressChartNotStarted = progressState.notStartedPercentage;
              rfp.ProgressChartInProgress = progressState.progressPercentage;
              rfp.ProgressChartComplete = progressState.completePercentage;
              rfp.ProgressChartcolor = "43D1C3";
              rfp.ProgressChartanimate = { duration: "1000", enabled: true };
              return rfp;
            }
          }),
          catchError((e) => {
            return of(rfp);
          })
        )
      }
      ),
      catchError((e) => {
        return of("Error Occure while calling Rfp Details Api " + e);

      }),
      shareReplay()
    )
  }

  isRfpExpired(rfp: any) {
    const deadline = moment(rfp.deadlineForQuestions, 'YYYY-MM-DD');
    deadline.set({ hour: 23, minute: 59, second: 59 });
    const now = moment();
    return now.isAfter(deadline);
  }

  getQuestionAttachments(rfpId, sectionId, questionId) {
    return this.http.get(`${environment.apiUrl}/vn/rfp/${btoa(rfpId)}/section/${btoa(sectionId)}/question/${btoa(questionId)}/attachment`);
  }
}
