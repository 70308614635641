import { Component, OnInit } from '@angular/core';
import { AuthenticationService, CommonService } from '../../_services';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isCaptchaResolved: any = false;
  captchaHasError: any = false;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  viewPasswordIcon:any = 'eye-slash';
  passwordType:any = 'password';
  error = '';
  RoutePrefix = "";
  isForgotPasswordSent=false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private commonService: CommonService
  ) { }
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      rememberMe: [false]
    });
    if (localStorage.getItem("vendorRememberMe") == 'true') {
      this.commonService.SetFormValue(this.loginForm, "email", localStorage.getItem("vendorUserName"));
      this.commonService.SetFormValue(this.loginForm, "password", localStorage.getItem("vendorPassword"));
      this.commonService.SetFormValue(this.loginForm, "rememberMe", true);
    }

    if(this.commonService.forgotPassSent){
      this.isForgotPasswordSent=true;
      this.commonService.forgotPassSent=false;
    }

    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.commonService.MarkAllAllCtrlDirty(this.loginForm);
      return;
    }
    this.captchaHasError = false;
    if(this.isCaptchaResolved == false || !this.isCaptchaResolved){
      this.captchaHasError = true;
      return false;
    }
    localStorage.removeItem("vendorRememberMe");
    localStorage.removeItem("vendorUserName");
    localStorage.removeItem("vendorPassword");
    this.loading = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value).then((res: any) => {
      this.loading = false;
      if (res.code === 200) {
        if (this.f.rememberMe.value) {
          localStorage.setItem("vendorRememberMe", 'true');
          localStorage.setItem("vendorUserName", this.f.email.value);
          localStorage.setItem("vendorPassword", this.f.password.value);
        }
        this.redirect();
      }
    }, (err) => {
      this.loading = false;
      console.log(err);
      if (err.status == 500) {
        localStorage.clear();
        this.router.navigate(['/login']);
      } else {
        this.error = err;
      }
      // this.errors = error.error.message;
    });
  }

  closeAlert(){
    this.isForgotPasswordSent=false;
  }

  redirect() {
    if (this.returnUrl && this.returnUrl !== '/') {
      this.router.navigate([this.returnUrl]);
    } else {
      this.router.navigate(['/']);
    }
  }

  viewPassword(){
    this.viewPasswordIcon = (this.viewPasswordIcon != 'eye' )?'eye': 'eye-slash';
    this.passwordType =  (this.viewPasswordIcon == 'eye' )?'text': 'password';
  }

  resolved(e){
    this.isCaptchaResolved = e;
  }
}
