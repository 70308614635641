import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService, AuthenticationService } from '../_services';
import { ApiResponse } from '../_models/response';
import { DatePicker } from '../const';
import { MdbTableDirective, MDBModalService } from 'ng-uikit-pro-standard';
import { ConfirmComponent } from '../_shared/confirm/confirm.component';
@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  dataSource: any = [];
  serverError = "";
  searchText: string = '';
  previous: string;
  DatePicker = DatePicker;
  deleteErrorMsg = "";

  constructor(
    private userservice: UserService,
    private modalService: MDBModalService,
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
    this.userservice.getAllvendors().subscribe((u: ApiResponse) => {
      if (u.code == 200) {
        console.log(u.data[0]);
        let allUsers = u.data[0].forEach((d, i) => {
          if (d.id != this.auth.currentUserValue.userid) {
            this.dataSource.push(d);
          }
        })
        this.mdbTable.setDataSource(this.dataSource);
        this.dataSource = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
      } else {
        this.serverError = u.message;
      }
    }, err => {
      this.serverError = err;
    })
  }
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.dataSource = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.dataSource = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }
  deletevendor(du, i) {
    this.deleteErrorMsg = "";
    this.modalService.show(ConfirmComponent).content.action.subscribe((result: boolean) => {
      if (result) {
        //Yes

        this.userservice.deletevendor(du.id).subscribe((b: ApiResponse) => {
          if (b.code == 200) {
            this.dataSource.splice(i, 1);
            this.mdbTable.setDataSource(this.mdbTable.getDataSource());
          } else {
            this.deleteErrorMsg = b.message;
          }
        }, err => {
          this.deleteErrorMsg = err;
        })
      }
    });

  }
}
