import { Directive, ElementRef, Input, Output } from '@angular/core';

@Directive({
  selector: '[hideMe]'
})

export class HideMeDirective {
  @Input() isHidden: boolean;
  constructor(private el: ElementRef) { }
  ngOnInit(){
    this.el.nativeElement.style.display = 'none';
  }
}
