import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Rfp } from 'src/app/_models/rfp';
import { DashboardStoreService } from 'src/app/_services';
import { DatePicker } from '../../../const';
@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit {
  backgroundInfo$:Observable<any[]>;
  constructor(
    public dashboardStoreService: DashboardStoreService,
  ) { }

  ngOnInit() {
    this.backgroundInfo$ = this.dashboardStoreService.getBackgroundInfo();
  }
}
