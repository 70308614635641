import { Injectable } from '@angular/core';
import { RfpService } from '../_services/rfp.service';
import { CommonService, UserService } from '../_services';

import { ApiResponse } from '../_models/response';
import { DatePicker, RFPWizardStepUrlSegment } from '../const';
import { Observable, Subject,BehaviorSubject  } from 'rxjs';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common'
import { AuthenticationService } from '../_services/authentication.service';
import { takeWhile } from 'rxjs/operators';
export enum StepperState{
  NEXT = -1,
  PREVIOUS = -2
}
@Injectable({
  providedIn: 'root'
})
export class SharedMethodService {


  private errorSubject = new Subject<string>();
  private warningSubject = new Subject<string>();
  private resultSubject = new Subject<any>();
  private stepperSubject = new Subject<any>();


  private isEditSubject = new BehaviorSubject<boolean>(false);

  private worksheetSelectedTabIndex = new Subject<number>();
  private paramSubject = new Subject<any>();
  private rfpIdSubject = new Subject<number>();


  public apiPath = environment.apiUrl;


  constructor(
    private rfpSetupService: RfpService,
    private commonservice: CommonService,
    public location: Location,
    private authenticationService: AuthenticationService
    ) {}



    getWorksheetSelectedTabIndex(){
      return this.worksheetSelectedTabIndex.asObservable();
    }

    setWorksheetSelectedTabIndex(index){
      return this.worksheetSelectedTabIndex.next(index);
    }

    setrfpIdSubject(data:any){
      this.rfpIdSubject.next(data);
    }

    getParamSubject(){
      return this.paramSubject.asObservable();
    }

    setParamSubject(param){

      this.paramSubject.next(param);
    }

    setError(msg: string){
      this.errorSubject.next(msg);
    }

    getError(){
      return this.errorSubject.asObservable();
    }

    setWarning(data){
        return this.warningSubject.next(data);
    }

    getWarning(){
      return this.warningSubject.asObservable();
    }

    setMessage(msg: string){
      this.resultSubject.next(msg);
    }

    getMessage(){
     return this.resultSubject.asObservable();
    }

    setStepperToNext(){
      this.stepperSubject.next(StepperState.NEXT);
    }

    setStepperToPrevious(){
      this.stepperSubject.next(StepperState.PREVIOUS);
    }

    setStepperTo(step){
      this.stepperSubject.next(step);
    }



    getStepperState(){
      return this.stepperSubject.asObservable();
    }
    getCurrentUserInfo(){
      return new Promise((resolve, reject) => {
        this.authenticationService.currentUser.subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
      });
    }

  }
