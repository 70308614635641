import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { VenderService, RfpService, DashboardStoreService }  from '../../_services';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RfpDashboardResolver implements Resolve<any> {

  constructor(
    private dashboardStoreService: DashboardStoreService,
    private venderService: VenderService,
    private rfpService: RfpService,
    ){}
  resolve(route: ActivatedRouteSnapshot): Observable<any>{
    const rfpId = route.paramMap.get("Id");
    const rfpStatus = route.paramMap.get("status");
    const rfpSubStatus = route.paramMap.get("subStatus");


    return  forkJoin([
      of({"rfpId": rfpId, "rfpStatus": rfpStatus, "rfpSubStatus": rfpSubStatus}),
      this.venderService.isRfxPaymentDoneByMe(rfpId),
      this.rfpService.rfpDetailObservable(rfpId).pipe(map(res=>{
        return res
      }), catchError(err=>{
        console.log(err)
        return of("Err Occured")
      })),
      this.venderService.isRfxPaymentDoneByMe( rfpId ).pipe(map(res=>{
        return res
      }), catchError(err=>{
        console.log(err)
        return of("Err Occured")
      }))
      ]).pipe(map(res=>{
      return {
        rfpIdAndStatus: res[0],
        isRfxPaymentDoneByMe: res[1],
        rfpData: res[2],
        isPaymentDone: res[3],
      }
    }));
  }
}
