import { Component, OnInit } from '@angular/core'; 

@Component({
  selector: 'app-in-progress-rfps',
  templateUrl: './in-progress-rfps.component.html',
  styleUrls: ['./in-progress-rfps.component.scss']
})
export class InProgressRfpsComponent implements OnInit {


  constructor() { }
  ngOnInit() {
  }

}
