import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { QuestionarieStates } from 'src/app/_models/rfp';
import { DashboardStoreService } from 'src/app/_services';
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  @Input('rfp_id') RfpId: string;
  @Input('rfp_status') rfpStatus: string;
  @Input('rfp_sub_status') rfpSubStatus: string;
  @Input('rfp_delegate_status') delegateStatus: string;
  @Input() canEdit: boolean;
  questionarieStates = QuestionarieStates;
  tabsConfig:any;
  subs = new Subscription();

  constructor(private dashboardStoreService: DashboardStoreService) { }

  ngOnInit() {
    this.subs.add(
      this.dashboardStoreService.getTabsConfig().subscribe((res:any)=>{
        this.tabsConfig = res;
      })
    );
  }
  ngOnDestroy(){
    this.subs.unsubscribe();
  }

  reloadQuestionsTab(){
    this.dashboardStoreService.init(this.RfpId);
  }
}
