import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService, AuthenticationService } from '../_services';
import { ApiResponse } from '../_models/response';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  fpForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  linkSent = false;
  linkSentMsg = '';
  RoutePrefix = "";
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private commonService: CommonService
  ) {

  }
  ngOnInit() {
    this.fpForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
  }
  onSubmit() {
    this.error = "";
    if (this.fpForm.valid) {
      this.loading = true;
      let oP = this.commonService.GetFormValue(this.fpForm, 'oldPassword');
      let nP = this.commonService.GetFormValue(this.fpForm, 'newPassword');
      let cP = this.commonService.GetFormValue(this.fpForm, 'confirmPassword');
      this.auth.changePassword(oP, nP, cP).subscribe((x: ApiResponse) => {
        if (x.code == 200) {
          this.linkSent = true;
          this.linkSentMsg = x.message;
        } else {
          this.error = x.message;
        }
        this.loading = false;
      }, err => {
        this.error = err;
        this.loading = false;
      })
    }
    else {
      this.commonService.MarkAllAllCtrlDirty(this.fpForm);
    }
  }

}
