import {Injectable, NgZone} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import Echo from 'laravel-echo';
import * as io from 'socket.io-client';
import {AuthenticationService} from './authentication.service';
import {Subject} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VenderService {

  newAnswerSubject = new Subject();
  answersViewed = new Subject();

  constructor(private http: HttpClient, private auth: AuthenticationService, private zone: NgZone) {

    auth.currentUser.subscribe((user) => {
      if (user) {
        // this.initSocket();
      }
    });
  }

  initSocket() {
    window['io'] = io;
    const currentUser = this.auth.currentUserValue;
    window['Echo'] = new Echo({
      broadcaster: 'socket.io',
      host: environment.serverUrl,
      auth: {
        headers: {
          Authorization: `Bearer ${currentUser.apiToken}`
        }
      }
    });

    const channel = `question-answered.${this.auth.currentUserValue.userid}`;

    window['Echo'].join(channel)
      .listen('.NewAnswerVendorQuestion', (res) => {
        this.zone.run(() => {
          this.newAnswerSubject.next(res.answer_info);
        });
      });
  }

  getAllInvitedVendors(rfp_id) {
    return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/invitedvendors`);
  }
  searchVendors(search_email) {
    return this.http.get(`${environment.apiUrl}/searchvendor?email=${search_email}`);
  }
  sendVendorsInvite(rfp_id, emails) {
    return this.http.post(`${environment.apiUrl}/invitevendors`, { rfp_id: rfp_id, email: emails })
  }
  deleteInvitedVendor(rfp_id, vendor_id) {
    return this.http.delete(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/invitedvendor/${btoa(vendor_id)}/delete`)
  }

  vendorupdate(frm,Id, token) {
        let setupObj = {
            fname: frm.firstName,
            lname: frm.lastName,
            company_name: frm.company,
            newpassword: frm.password,
            confirmpassword: frm.confirmPassword,
        }
        return this.http.put(`${environment.apiUrl}/reset/with/company/password/${Id}/${token}`, setupObj);
    }

  getDeligatesByVendorAdmin( rfx_id ) {
    return this.http.get(`${environment.apiUrl}/vn/vendor/rfx/${btoa(rfx_id)}/delegates`);
  }

  assignDelegatesToRfx( rfx_id, in_data ) {
    return this.http.post(`${environment.apiUrl}/vn/vendor/rfx/${btoa(rfx_id)}/delegate`, in_data );
  }

  unAssignVendorsToRfx( rfx_id, delegate_id ) {
    return this.http.delete(`${environment.apiUrl}/vn/vendor/rfx/${btoa(rfx_id)}/delegate/${btoa(delegate_id)}`)
  }

  reAssignVendorsToRfx( rfx_id, delegate_id ) {
    let obj = {};
    return this.http.put(`${environment.apiUrl}/vn/vendor/rfx/${btoa(rfx_id)}/delegate/${btoa(delegate_id)}/restore`, obj)
  }

  getAssignVendorsByRfx( rfx_id ) {
    return this.http.get(`${environment.apiUrl}/vn/vendor/rfx/${btoa(rfx_id)}/delegate/assigned`);
  }

  updateDeligateRole( rfx_id, delegate_id, rfx_role ) {
    let setupObj = {
      role : rfx_role
    }
  return this.http.put(`${environment.apiUrl}/vn/vendor/rfx/${btoa(rfx_id)}/delegate/${btoa(delegate_id)}`, setupObj);
  }

  isRfxPaymentDoneByMe( rfx_id ) {
    return this.http.get(`${environment.apiUrl}/vn/vendor/rfx/${btoa(rfx_id)}/payment-status`);
  }

  getSettings() {
    return this.http.get(`${environment.apiUrl}/settings`);
  }

  isCompanyNameExists( company_name ) {
    return this.http.get(`${environment.apiUrl}/isCompanyNameExists?company_name=${company_name}`);
  }

  assignVendorToCompany(frm,Id, token) {
    console.log("Here You GO");
    let setupObj = {
      fname: frm.firstName,
      lname: frm.lastName,
      company_id: frm.company_id,
      newpassword: frm.password,
      confirmpassword: frm.confirmPassword,
    }
    return this.http.put(`${environment.apiUrl}/reset/with/companyExists/password/${Id}/${token}`, setupObj);
  }
  AddNewDeligate(frm,rfx_id){

    let setupObj = {
      contact_fname: frm.firstName,
      contact_lname: frm.lastName,
      email: frm.emailid,
      invitation_message: frm.invitationmessage,
      roles: frm.roles
    }
    if(frm.force){

      setupObj['force'] = frm.force
    }
    return this.http.post(`${environment.apiUrl}/vn/vendor/rfx/${btoa(rfx_id)}/delegate`, setupObj);
  }

    submitVendorRFxDetail(rfp_id){
      let obj = {};
      return this.http.put(`${environment.apiUrl}/vn/vendor/rfp/${btoa(rfp_id)}/vendorsubmitrfx`, obj);
    }

    createQuestion(rfpId: string, question: string) {
      return this.http.post(`${environment.apiUrl}/vn/vendor/rfp/${btoa(rfpId)}/ask-question`, { question });
    }

    getQuestions(rfpId: string, page = 0) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/vendor-qa?is_private=false&page=${page}`);
    }

}
