import { Component, Input, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from 'src/app/_models';
import { ApiResponse } from 'src/app/_models/response';
import { Rfp, Section, QuestionarieStates, Answer, QuestionType } from 'src/app/_models/rfp';
import { AuthenticationService, DashboardStoreService } from 'src/app/_services';
import { environment } from 'src/environments/environment';
import { DatePicker } from '../../../../const';
import { MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { ConfirmComponent } from '../../../../_shared/confirm/confirm.component';
import { RfpService } from 'src/app/_services';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';
import { CommonService } from 'src/app/_services';
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';
@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
  @Input('rfp_id') RfpId: string;
  @Input('is_disabled') isDisabled: boolean;
  @Input('rfp_status') rfpStatus: string;
  @Input('rfp_sub_status') rfpSubStatus: string;
  @Input('rfp_delegate_status') delegateStatus: string;
  @Input() canEdit: boolean;
  @ViewChild('inputFile',{ read: ElementRef, static: false }) inputFile: ElementRef;
  fileInput
  questionarieStates = QuestionarieStates;
  hasFileError:any = false;
  hasSizeError = false;
  object = Object;
  files: UploadFile[];
  QuestionType = QuestionType;
  uploadInput: EventEmitter<UploadInput>;
  allSections: Section[];
  uploadedfiles: any = [];
  allSections$: Observable<any>;
  activeSectionId$ = new BehaviorSubject<number>(-1);
  activatedSectionId: string;
  activatedSection: Section;
  totalSections: number;
  questionsAreEditable: boolean;
  allCompleteStatus: any = {};
  tabsConfig: any;
  uplaodAttchementFormError = "";
  answerAttachmentFormGroup: FormGroup;
  SelectedQuestion = <any>{};
  prevValue = "";
  lastValue = '';
  public apiPath = environment.apiUrl;
  afterConfirmDelete:any = {};
  currentUser: User;
  imgPath = this.apiPath + "/rfp/attachment/";
  subs = new Subscription();
  clickedQuestionAttachementButton: any = null;
  constructor(private dashboardStoreService: DashboardStoreService, 
    private rfpService: RfpService, 
    private auth: AuthenticationService, 
    private modalService: MDBModalService, 
    private toast: ToastService,
    private commonService: CommonService) {
    this.saveAnswer = debounce(this.saveAnswer, 2000);
    this.uploadInput = new EventEmitter<UploadInput>();
    this.files = [];
  }

  ngOnInit() {
    this.subs.add(
      this.dashboardStoreService.getTabsConfig().subscribe((res: any) => {
        this.tabsConfig = res;
      })
    );

    this.answerAttachmentFormGroup = new FormGroup({
      Files: new FormControl(''),
    })

    this.auth.currentUser.subscribe(x => {
      this.currentUser = x;
    });

    this.dashboardStoreService.getSectionCompleteStatus().subscribe((completeStatus: any[]) => {
      this.allCompleteStatus = completeStatus;
    });

    this.activeSectionId$.asObservable().subscribe((activatedSectionId: number) => {
      if (activatedSectionId > -1) {
        this.activatedSection = this.allSections[activatedSectionId];
        this.activatedSectionId = "" + activatedSectionId;

        if (this.allSections[activatedSectionId].questions) {
          if (Object.keys(this.allSections[activatedSectionId].questions).length > 0) {
            Object.keys(this.allSections[activatedSectionId].questions).forEach((q, i) => {
              if (this.allSections[activatedSectionId].questions[q]) {
                const answers = this.allSections[activatedSectionId].questions[q].answers;
                if (answers)
                  if (answers.length > 0) {
                    if (this.allSections[activatedSectionId].questions[q].type == 'list') {
                      this.allSections[activatedSectionId].questions[q].savedAnswer = this.allSections[activatedSectionId].questions[q].answers[0].option;//option
                    } else {
                      this.allSections[activatedSectionId].questions[q].savedAnswer = this.allSections[activatedSectionId].questions[q].answers[0].answer;//text
                    }
                  }
              }
            })
          }
        }

        this.allSections[activatedSectionId].isDisabled = this.isDisabled;
        this.allSections[activatedSectionId] = new Section(this.allSections[activatedSectionId]);
        this.activatedSection = this.allSections[activatedSectionId];
      }
    });

    this.dashboardStoreService.getAllSections().pipe(
      tap((res: Section[]) => {
        this.allSections = res;
        // console.log(this.allSections);
        let keys = Object.keys(this.allSections);
        this.totalSections = keys.length;
        let activatedSectionId = parseInt(keys[0]);
        this.activeSectionId$.next(activatedSectionId);
      })
    ).subscribe();
  }

  selectSection(activatedSectionId: number) {
    this.rfpService.questionWithAnswer(this.RfpId).subscribe((x: any) => {
      if (x.code == 200 && x.data["0"] && x.data["0"].length > 0) {

        x.data["0"][0].sections.forEach((ss: Section, ii) => {
          const questions = ss.questions.reduce(function (acc, curr) {
            acc[curr.id] = curr;
            return acc;
          }, {})
          ss.questions = questions;
          this.allSections[ss.id] = ss;
        });
      }

      this.activeSectionId$.next(activatedSectionId);
    });

  }

  allQuestionsAnswered(activatedSectionId): boolean {
    let totalQuestinStored = this.allSections[activatedSectionId].questions;

    for (let key of Object.keys(totalQuestinStored)) {
      if (this.activatedSection.questions[key].answers.length <= 0) {
        return false;
      }
    }
    return true;
  }

  saveAnswerCheckbox(answer, q, activatedSectionId, id) {
    this.saveAnswerToDB(answer, q, activatedSectionId, id);
  }

  saveAnswer(answer, q, activatedSectionId, id, confirmModal = null) {
    if(answer.trim() == ''){
      confirmModal.show();
      this.afterConfirmDelete = {
        answer: answer,
        q: q,
        activatedSectionId: activatedSectionId,
        id: id
      };
    }else{
      if(answer.trim() != ''){
        this.lastValue = answer;
      }
      this.saveAnswerToDB(answer, q, activatedSectionId, id);
    }
  }

  saveInitialValue(answer, q, activatedSectionId, id){
    this.lastValue = answer;
  }

  yesDeleteAnswer(yesDeleteAnswer){
    this.saveAnswerToDB(this.afterConfirmDelete['answer'], this.afterConfirmDelete['q'], this.afterConfirmDelete['activatedSectionId'], this.afterConfirmDelete['id']);
    yesDeleteAnswer.hide();
  }

  saveOnBlur(answer, q, activatedSectionId, id, confirmModal = null) {
    if (this.prevValue != answer) {
      this.prevValue = answer;
      if(answer.trim() == ''){
        confirmModal.show();
      }else{
        if(answer != ''){
          this.lastValue = answer;
        }
        this.saveAnswerToDB(answer, q, activatedSectionId, id);
      }
    }
  }

  saveAnswerToDB(answer, q, activatedSectionId, id, callback = null) {
    let loaderID = "loader_" + id;
    console.log('save answer');
    document.getElementById(loaderID).style.display = "block";
    if (answer) {
      let ans: Answer = new Answer();
      // adding answer to store
      if (this.allSections[activatedSectionId].questions[q.id].type == "list") {
        ans.option = answer;
      } else {
        ans.answer = answer;
      }

      let selectedOption = null;
      if (q.mcq_id) {
        selectedOption = answer;
        answer = '';
      }
      let hasAttachment = false;
      if(this.files.length != 0){
        hasAttachment = true;
      }
      this.dashboardStoreService.saveAnswer(this.activatedSectionId, q.id, answer, selectedOption, hasAttachment).subscribe(
        (res: any) => {
          document.getElementById(loaderID).style.display = "none";
          //this.commonService.ShowLoader=true;
          if (res.data && res.data.answer_id) {
            if(callback !== null){
              callback(res);
            }
            ans.id = res.answer_id;
            this.activatedSection.questions[q.id].answers.push(ans);
            this.activatedSection.questions[q.id].savedAnswer = answer;
            this.activatedSection.questions[q.id].saved_answer_id = res.data.answer_id;
            // if allQAnswered in this Section then set allQAnswered
            if (this.allQuestionsAnswered(activatedSectionId)) {
              this.activatedSection.allQAnswered = true;
            }
          } else {
            // throw Error
          }
        }
      );
    } else {
      // adding answer to store
      this.activatedSection.questions[q.id].answers = [];
      this.activatedSection.allQAnswered = false;
      this.dashboardStoreService.saveAnswer(this.activatedSectionId, q.id, "", null).subscribe((res: any) => {
        document.getElementById(loaderID).style.display = "none";
      });
    }
  }
  markAsComplate(section_Id) {

    if (this.activatedSection.allQAnswered) {
      this.dashboardStoreService.markSectionComplete(section_Id).subscribe((x: ApiResponse) => {
        if (x.code == 200) {
          this.activatedSection.isMarkCompleted = true;
        } else {
          // this.showError(this.rfpsErrorMsg +" "+ x.message);
          // this.rfpsErrorMsg += x.message;
        }
      }, err => {
        // this.showError(this.rfpsErrorMsg +" "+ err);

        // this.rfpsErrorMsg += err;
      })
    }
  }

  markAsInComplete(section_Id) {
    this.dashboardStoreService.markSectionInComplete(section_Id).subscribe((x: ApiResponse) => {
      if (x.code == 200) {
        this.activatedSection.isMarkCompleted = false;
      } else {
        // this.showError(this.rfpsErrorMsg +" "+ x.message);
        // this.rfpsErrorMsg += x.message;
      }
    }, err => {
      // this.showError(this.rfpsErrorMsg +" "+ err);

      // this.rfpsErrorMsg += err;
    });
  }

  // uplaodAnswerFiles(files: FileList, answer) {
  //   //let answerid :number;
  //   // if(typeof answer.id == 'string'){
  //   //   answerid = +answer.id;
  //   // }else{
  //   //   answerid = answer.id;
  //   // }
  //   //console.log(answerid);
  //   this.uplaodAttchementFormError = "";
  //   for (let i = 0; i < files.length; i++) {
  //     this.dashboardStoreService.uploadAnswerFiles(files.item(i), parseInt(answer.id)).subscribe((c: ApiResponse) => {
  //       if (c.code == 200) {
  //         answer.attachment = answer.attachment || [];
  //         answer.attachment.push(c.data[0]);
  //       } else {
  //         this.uplaodAttchementFormError += (c.message);
  //       }
  //     }, err => {
  //       this.uplaodAttchementFormError += (err)
  //     })
  //   }
  // }

  deleteAnswerAttachment(as, asIdex, at, atIndex) {
    this.uplaodAttchementFormError = "";
    this.modalService.show(ConfirmComponent).content.action.subscribe((result: boolean) => {
      if (result) {
        //Yes
        this.dashboardStoreService.deleteAnswerAttachment(as, asIdex, at, atIndex).subscribe((x: ApiResponse) => {
          if (x.code == 200) {
            as.attachment.splice(atIndex, 1);
          } else {
            console.error(x.message)
          }
        }, err => {
          console.error(err);
        })
      }
    });
  }

  getIcon(name) {
    if (name) {
      //
      let ixons = ["gif", "jpeg", "jpg", "png", "svg","pdf",'xls','xlsx'];
      let pname = "";
      ixons.forEach((ex, t) => {
        if (name.toLowerCase().indexOf("." + ex) != -1) {
          pname = "/assets/images/" + ex + ".png";
        }
      })
      if (pname) {
        return pname;
      }
      return "/assets/images/downloadIcons/default.svg";
    }
  }

  getLink(path: string) {
    return `${environment.serverUrl}${path}`;
  }

  closeAttachmentModal(modal) {
    modal.hide();
  }

  showUploadAttachmentModal(modalName,questionData,id) {
    this.SelectedQuestion = questionData;
    console.log(questionData);
    if(questionData.saved_answer_id === undefined){
      this.saveAnswerToDB(' ',questionData, questionData.section_id, id, (answer)=>{
        modalName.show();
      });
    }else{
      modalName.show();
    }
  }

  showAttachmentModal(modalName,singleQuestion){
    this.clickedQuestionAttachementButton = singleQuestion;
    this.getQuestionAttachments(singleQuestion.section_id,singleQuestion.id, modalName);
  }

  getQuestionAttachments(sectionId,questionId,modalName){
    this.uploadedfiles = [];
    this.dashboardStoreService.getQuestionAttachments(sectionId, questionId).subscribe((res: any) => {
      if (res) {
        res.data[0].forEach(element => {
          this.uploadedfiles.push(element);
        });
        modalName.show();
      }
    });
  }

  validateFileTypes(fileName) {
    let alowedExtensions = ['jpeg', 'png', 'jpg', 'gif', 'pdf', 'xls', 'doc', 'docx', 'xlsx', 'zip', 'ppt','pptx','tif', 'txt'];
    let fileExtension = fileName.split('.');
    if (alowedExtensions.includes(fileExtension[fileExtension.length-1])) {
      return true;
    } else {
      return false;
    }
  }

  onUploadOutput(output: UploadOutput | any): void {
    if (output.file !== undefined && output.type == 'addedToQueue') {
      let name = output.file.nativeFile.name;
      if (!this.validateFileTypes(name)) {
        let extension = name.split('.');
        this.toast.error(extension[extension.length-1] + ' file type is not allowed to upload!');
        this.hasFileError = true;
      }else{
        this.hasFileError = false;
      }

      if(output.file.size > 6291456){
        this.toast.error('File size should max upto 6MB');
        this.hasSizeError = true;
      }else{
        this.hasSizeError = false;
      }
    }
    this.inputFile.nativeElement.value = '';
    if(this.hasFileError == false && this.hasSizeError == false){
      if (output.type === 'allAddedToQueue') {
        const event: UploadInput = {
          type: 'uploadAll',
          url: `${environment.apiUrl}/vn/vendor/answer/attachment`,
          method: 'POST',
          data: {
            answer_id: this.SelectedQuestion.saved_answer_id,
            rfp_id: this.RfpId
          },
          headers: {
            "Authorization": `Bearer ${this.auth.currentUserValue.apiToken}`
          }
        };

        this.uploadInput.emit(event);
      } else if (output.type === 'addedToQueue') {
        // add file to array when added
        this.files.push(output.file);
      } else if (output.type === 'uploading') {
        // update current data in files array for uploading file
        const index = this.files.findIndex(file => file.id === output.file.id);
        this.files[index] = output.file;
      } else if (output.type === 'done') {
        this.files = [];
        if (output.file.response.type == 200) {
          this.uploadedfiles.push(output.file.response.attach);
          this.toast.success(output.file.response.message);
        }
        else {
          if (output.file.responseStatus == 413) {
            this.toast.warning("File size cann't be greater than 2MB.");
          }
          else if(output.file.responseStatus == 400){
            this.toast.warning(output.file.response.message);
          }
          else {
            this.toast.success(output.file.response.message);
            this.allSections[this.activatedSectionId].questions[this.SelectedQuestion.id].answers[0].attachment.push(output.file.response.data[0]);
          }
        }
      } else if (output.type === 'removed') {
        // remove file from array when removed
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
      } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
        this.files = [];
        this.toast.error("Can't upload this file.");
      }
    }else{
      this.uploadInput.emit({
        type: 'removeAll'
      });
    }
  }

  encodeId(id){
    return btoa(id);
  }

  hideConfirmBox(modalName){
    try{
      this.allSections[this.afterConfirmDelete['activatedSectionId']].questions[this.afterConfirmDelete['q'].id].savedAnswer = this.lastValue;
    }catch(e){}
    
    modalName.hide();
  }
}
