import { IMyOptions } from 'ng-uikit-pro-standard';
import { retry } from 'rxjs/operators';

export const DatePicker: DatePicker = {
  Options: {
    dateFormat: "mm-dd-yyyy",
    closeBtnTxt: "OK"
  },
  Placeholder: "mm-dd-yyyy",
  ApiFormat: (dt: string) => {
    //mm-dd-yyyy to mm/dd/yyyy
    if (!dt) {
      return "";
    }
    let ar = dt.split("-");

    return ar[0] + "/" + ar[1] + "/" + ar[2];
  },
  ApiToUiFormat: (dt: string) => {
    //yyyy-mm-dd to mm-dd-yyy
    let d = new DateAndTime();
    if (!dt) {
      return d;
    }
    var dtArray = dt.split(" ")[0].split("-");
    var time = "";
    let hour = 0;
    let minute = 0;
    if (dt.split(" ").length == 2) {
      time = dt.split(" ")[1];
      hour = parseInt(time.split(":")[0]);
      minute = parseInt(time.split(":")[1]);
    }
    if (time) {
      d.Time = tConvert(time);
    }
    let dd = parseInt(dtArray[2]);
    let mm = parseInt(dtArray[1]);
    let yy = parseInt(dtArray[0]);
    d.Date = mm + "-" + dd + "-" + yy;
    d.DateObject = new Date(yy, mm, dd, hour, minute);
    return d;
  },
};

interface DatePicker {
  Options: IMyOptions;
  Placeholder: string;
  ApiFormat(dt: string): string;
  ApiToUiFormat(dt: string): DateAndTime;
}

class DateAndTime {
  Date: string;
  Time: string;
  DateObject: Date
}

function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice(1);  // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
    if (time[0] < 9) {
      time[0] = "0" + time[0];
    }
    time[3] = "";//Remove Seconds
  }
  return time.join(''); // return adjusted time or original string
}

export const RFPWizardStepUrlSegment = {
  ProjectSetup: "",
  Template: "template",
  BGInfo: "bginfo",
  Appedix: "appendix",
  Questions: "questions"
}
