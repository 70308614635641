export enum rfpStatus{
  'Completed','Published','Canceled','Draft','Closed','Blocked'
}

export enum writeStatus{'Pending', 'Completed'}
export enum approveStatus{'Pending', 'Approved'}

interface PocUser{
  id: number;
  company_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  domain: any;
  email: string;
  fName: string;
  lName: string;
  invitation_messgage: string;
  isConfirmed: boolean;
  phone: string;
  remeberToken: any;
  role_id: number;
  status: number;
  type: string;
}

interface Company{
  address: string;
  companySize: number;
  created_at: string;
  description: string;
  id: number;
  name: string;
  phone: string;
  pincode: string;
  publicName: string;
  timezone_id: string;
  updated_at: string;
  user_id: number;
  website: string;
}

interface Invite{
  inviteId: number;
  rfpStatus: string;
  status: string;
  vendor_id: number;
  vendor_company_id: number;
}
export enum QuestionarieStates{
  Disabled = 1,
  Visible = 2,
  AcceptAndPay = 3,
  PleasePay = 4
}
export type TabsConfig = {
  is_secured_questions : boolean;
  payer : string;
  show_all : boolean;
  payment_needed : boolean;
  rfpStatus: string;
  rfpSubStatus: string;
  questionarie: QuestionarieStates;
  delegate: string;
  isHideWorksheet: boolean;
  mainSupplierCanSubmit: false
}
export class Rfp{
  activities: [];
  sections:Section[];
  id: number;
  invite_type: string;
  name: string;
  description: string;
  poc_user_id: number;
  poc_user: PocUser;
  company_id: number;
  company: Company;
  rfpCreator_user_id: number;
  isPrivate: boolean;
  results: string;// result date
  deadlineForQuestions:string;
  finalistDemo: string;
  qaWebinarSession: string;
  followUpQuestions: string;
  timezone_id: number;
  budget: string;
  // inviteStatus: Invite;
  invite: Invite;
  showBudget: boolean;
  paymentTerms: boolean;
  clonedRfpId: number;
  // status: rfpStatus;
  // subStatus: rfpStatus;
  // delegStatus: rfpStatus;
  status: string = "";
  subStatus: string = "";
  delegStatus: string = "";
  rolename?: string;
  is_secured_questions: boolean;
  payer: string;
  deleted_at:string;
  created_at:string;
  updated_at:string;
  need_help: boolean;
  ProgressChartcolor: string= "43D1C3";
  ProgressChartanimate: any = {duration: "10", enabled: true};
  ProgressChartNotStarted: number = 0;
  ProgressChartInProgress: number = 0;
  ProgressChartComplete: number = 0;
  payment_message: string;
}

export class BackgroundInfo{
  id:number;
  title:string;
  description: string;
  child_background:BackgroundInfo;
}

export class Section{
  constructor(obj: any){
    this.allQAnswered     = obj.allQAnswered;
    this.id               = obj.id;
    this.questions        = obj.questions;
    this.title            = obj.title;
    this.description      = obj.description;
    this.isDisabled       = obj.isDisabled;
    this.isMarkCompleted  = obj.isMarkCompleted;
  }
  allQAnswered: boolean;
  id: number;
  questions: any[];
  title: string;
  description:string;
  isDisabled: boolean;
  isMarkCompleted: boolean;

  isMarkCompletedDisabled():boolean{
    return !(this.allQAnswered ) || this.isMarkCompleted
  }

  isEditSectionEnabled():boolean{
    return (this.allQAnswered ) && this.isMarkCompleted && !this.isDisabled
  }

  areQuestionsDisabled(){
    return this.isDisabled ||  this.isMarkCompleted;
  }
  points: number;
  type: string;
  isActive: boolean;
}

export enum QuestionType{
  Text ='text',
  List = 'list'
}

export class Question{
  question_id: number;
  answers: Answer[];
  title: string;
  evaluation_criteria: string;
  type: QuestionType;
  created_at: string;
  updated_at: string;
  vendorHelpText: string;
  scoreHelpText: string;
  points: number;
  mcq_id: number;
  canSelectMany: boolean;
  seqnence: number;
}

export class Answer{
  id: number;
  vendor_id?: number;
  rfp_id: number;
  section_id: number;
  question_id: number;
  company_id?: number;
  answer: string;
  option: any;
  status: number;
  created_at?: string;
  updated_at?: string;
  points_given: any;
  attachment: [] = [];
}
