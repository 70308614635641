import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService, CommonService } from '../_services';
import { ApiResponse } from '../_models/response';
import { CompaniesService } from '../_services/companies.service';
import { environment } from '../../environments/environment.prod';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  userId = 0;
  user: any = {};
  company: any = {};
  company_id = 0;
  serverError = "";
  isAdmin = false;
  RoutePrefix = "";
  constructor(private auth: AuthenticationService,
    private userService: UserService,
    private companiesService: CompaniesService,
    public commonservice: CommonService
  ) { }
  ngOnInit() {
    this.isAdmin = this.auth.currentUserValue.isAdmin;
    this.userId = this.auth.currentUserValue.userid;
    this.userService.getUser(this.userId).subscribe((u: ApiResponse) => {
      if (u.code == 200) {
        this.user = u.data[0];
        this.companiesService.getCompany(this.userId).subscribe((c: ApiResponse) => {
          if (c.code == 200) {
            this.company = c.data[0].company;
          } else {
            this.serverError = c.message;
          }
        }, err => {
          this.serverError = err;
        })
      } else {
        this.serverError = u.message;
      }
    }, err => {
      this.serverError = err;
    });

  }

  getFirstLast(f, l) {
    if (f && l) {
      return (f.substring(0, 1) + l.substring(0, 1)).toUpperCase();
    }
    if (f) {
      return (f.substring(0, 1)).toUpperCase();
    }
    if (l) {
      return (l.substring(0, 1)).toUpperCase();
    }
  }
  getCompanySortName(n: string) {

    if (n) {
      if (n.length > 1) {
        return (n.substring(0, 1) + (n.substring(1, 2))).toUpperCase();
      }
      return (n.substring(0, 1)).toUpperCase();

    }
    return "";
  }

}
