import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { BackgroundInfo, Rfp, Section, TabsConfig, QuestionarieStates } from '../_models/rfp'
import { RfpService } from './rfp.service';
import { VenderService } from './vender.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardStoreService {
  rfpDetailsSubject = new BehaviorSubject<Rfp>(null);
  rfp$:Observable<Rfp> =  this.rfpDetailsSubject.asObservable();

  backgroundInfoSubject = new BehaviorSubject<BackgroundInfo[]>([]);
  backgroundInfo$:Observable<any[]> =  this.backgroundInfoSubject.asObservable();

  sectionsSubject = new BehaviorSubject<any>({});
  sections$: Observable<any> = this.sectionsSubject.asObservable();

  sectionsCompletionStatusSubject = new BehaviorSubject<any>([]);
  sectionsComplete$: Observable<any[]> = this.sectionsCompletionStatusSubject.asObservable();

  reviewSubject = new BehaviorSubject<any[]>([]);
  review$: Observable<any[]> = this.reviewSubject.asObservable();

  rfpCompletedSubject = new BehaviorSubject<boolean>(false);
  rfpCompleted$ = this.rfpCompletedSubject.asObservable();

  assignedDeligatesSubject = new BehaviorSubject<any[]>([]);
  assignedDeligates$ = this.assignedDeligatesSubject.asObservable();

  private paymentDoneSubject  = new BehaviorSubject<boolean>(false);
  paymentDone$:Observable<boolean> = this.paymentDoneSubject.asObservable();

  rfxAmountSubject = new BehaviorSubject<string>("");
  $:Observable<string> = this.rfxAmountSubject.asObservable();

  rfp_id: string;
  rfp:Rfp;

  tabsConfig: TabsConfig = {
    is_secured_questions : false,
    payer : null,
    show_all : false,
    payment_needed : false,
    rfpStatus: "pending",
    rfpSubStatus: undefined,
    questionarie: QuestionarieStates.Disabled,
    delegate: "hidden",
    isHideWorksheet: false,
    mainSupplierCanSubmit: false};
  private tabsConfigSubject = new BehaviorSubject<TabsConfig>(this.tabsConfig);

  public tabsConfig$ = this.tabsConfigSubject.asObservable();
  constructor(private rfpService: RfpService,
    private venderService: VenderService) { }

  updatePaymentStatus(status: boolean){
    this.paymentDoneSubject.next(status);
  }

  updateRfpCompletedStatus(sts: boolean){
    this.rfpCompletedSubject.next(sts);
  }

  init(rfp_id:any){
    // RfpDetail is_secured_questions
    this.rfp_id = rfp_id;
    // this.rfpService.rfpDetail(this.rfp_id).pipe(
    //     switchMap((res:any)=>{
    //         return this.rfpService.getActivity(this.rfp_id).pipe(
    //           map((res1:any)=>{
    //             if (res1.code == 200) {
    //               let rfp: Rfp = res.data.rfp;
    //               rfp.activities = res1.data[0];
    //               this.rfp = rfp;
    //               return rfp;
    //             }
    //           }),
    //           catchError((e)=>{
    //             return  of(res.data.rfp);
    //           })
    //         )
    //     }),
    //     switchMap((rfp:any)=>{
    //       return  this.rfpService.chart(this.rfp_id).pipe(
    //         map((progressState:any)=>{
    //           if (progressState.code == 200) {
    //             var progressState = progressState.data[0];
    //             rfp.ProgressChartNotStarted = progressState.notStartedPercentage;
    //             rfp.ProgressChartInProgress = progressState.progressPercentage;
    //             rfp.ProgressChartComplete = progressState.completePercentage;
    //             rfp.ProgressChartcolor = "43D1C3";
    //             rfp.ProgressChartanimate = {duration: "1000", enabled: true};
    //             return rfp;
    //           }
    //         }),
    //         catchError((e)=>{
    //           return of(rfp);
    //         })
    //       )
    //     }
    //    ),
    //    catchError((e)=>{
    //      return of("Error Occure while calling Rfp Details Api "+ e);

    //    }),
    //    shareReplay()
    // ).subscribe((res:Rfp)=>{

    //   res.status = param.status;
    //   res.subStatus = param.subStatus || '';
    //   res.delegStatus = res.subStatus;

    //   this.rfpDetailsSubject.next(res);
    // });

    // BackgroundInfo
    this.rfpService.background(this.rfp_id).pipe(map((res:any)=>{
      if(res.code == 200){
        this.backgroundInfoSubject.next(res.data[0]);
      }
    }),
    catchError((error)=>{
      // setup Error Handling Facility
      return of(error);
    })
    )
    .subscribe();

    // Sections
    this.rfpService.questionWithAnswer(this.rfp_id).pipe(
      map((x:any)=>{
        let allSections =  {};
        if (x.code == 200 && x.data["0"] && x.data["0"].length > 0) {

          x.data["0"][0].sections.forEach((ss:Section, ii) => {
            const questions = ss.questions.reduce(function(acc, curr) {
              acc[curr.id] = curr;
              return acc;
            }, {})
            ss.questions = questions;
            allSections[ss.id] = ss;
          });
        }
        let keys= Object.keys(allSections);
        if(allSections[keys[keys.length-1]]){
          allSections[keys[keys.length-1]].isActive = true;
        }
        // console.log('called before next');
        this.sectionsSubject.next(allSections);
      },
      shareReplay()
      ))
      .subscribe();

    // Review Tab
    this.rfpService.reviewRfpCompletion(this.rfp_id).pipe(
      map((res:any)=>{
        if (res.code == 200) {
          if (res.data.length > 0) {
             this.reviewSubject.next(res.data[0]);
          }
        }
      })
    )
    .subscribe();

    this.rfpService.getAssignVendorsByRfx(this.rfp_id).pipe(
      map((res:any)=>{
        if( res.code == 200 ) {
          if( res.data[0].length > 0 ) {
            this.assignedDeligatesSubject.next(res.data[0]);
          }
        }
      })
    )

    this.tabsConfig$.subscribe((res:any)=>{
      this.tabsConfig = res;
    });

  }

  getTabsConfig(){
    return this.tabsConfig$;
  }

  updateSectionCompleteStatus(status: any){
    this.sectionsCompletionStatusSubject.next(status);
  }

  reloadCharts(){
    this.rfpService.rfpDetail(this.rfp_id).pipe(
        switchMap((res:any)=>{
            return this.rfpService.getActivity(this.rfp_id).pipe(
              map((res1:any)=>{
                if (res1.code == 200) {
                  let rfp: Rfp = res.data.rfp;
                  rfp.activities = res1.data[0];
                  this.rfp = rfp;
                  return rfp;
                }
              }),
              catchError((e)=>{
                return  of(res.data.rfp);
              })
            )
        }),
        switchMap((rfp:any)=>{
          return  this.rfpService.chart(this.rfp_id).pipe(
            map((progressState:any)=>{
              if (progressState.code == 200) {
                var progressState = progressState.data[0];
                rfp.ProgressChartNotStarted = progressState.notStartedPercentage;
                rfp.ProgressChartInProgress = progressState.progressPercentage;
                rfp.ProgressChartComplete = progressState.completePercentage;
                rfp.ProgressChartcolor = "43D1C3";
                rfp.ProgressChartanimate = {duration: "1000", enabled: true};
                return rfp;
              }
            }),
            catchError((e)=>{
              return of(rfp);
            })
          )
        }
       ),
       catchError((e)=>{
         return of("Error Occure while calling Rfp Details Api "+ e);

       }),
       shareReplay()
    ).subscribe((res:Rfp)=>{

      this.rfpDetailsSubject.next(res);
    });
  }
  updateTabsConfig(tabsConfig: any){
    
    for(let key of Object.keys(tabsConfig)){
      this.tabsConfig[key] = tabsConfig[key]
    }
    this.tabsConfigSubject.next(this.tabsConfig);
  }

  getRfpDetails(): Observable<Rfp>{
    return this.rfp$;
  }

  getBackgroundInfo():Observable<BackgroundInfo[]>{
    return this.backgroundInfo$;
  }

  getAllSections(): Observable<any[]>{
    return this.sections$;
  }

  getSectionCompleteStatus(){
    return this.sectionsComplete$
  }

  markSectionComplete(sectionId: number){
    return this.rfpService.markAsComplete(sectionId);
  }

  markSectionInComplete(sectionId: number){
    return this.rfpService.markAsInComplete(sectionId);
  }

  saveAnswer( sectionId:string, questionId:number, answer:string, selectedOption: string, hasAttachment: boolean = false){
    // 1 allAnswered, 0 NotAllAnswered, -1 from AllAnswered to !AllAnswered

    return this.rfpService.saveAnswer(this.rfp_id, sectionId, questionId, answer, selectedOption, hasAttachment);
  }

  uploadAnswerFiles(file, questionId:number){
    return this.rfpService.postAnswerAttachment(this.rfp_id, questionId, file);
  }

  deleteAnswerAttachment(as, asIdex, at, atIndex){
    return this.rfpService.answerAttachmentDelete(this.rfp_id, as.id, at.id);
  }

  getSetupFiles(){
    return this.rfpService.getSetupFiles(this.rfp_id);
  }

  getQuestionAttachments(sectionId, questionId){
    return this.rfpService.getQuestionAttachments(this.rfp_id,sectionId,questionId);
  }

}
