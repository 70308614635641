export class User {
  userid: number;
  usertype: string;
  fName: string;
  lName: string;
  email: string;
  password: string;
  company_id: number;
  comp_name: string;
  apiToken?: string;
  isAdmin: boolean;
  role:{
  	name:''
  };
} 
