import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { AuthenticationService, CommonService } from '../_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private commonService: CommonService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      this.commonService.ShowLoader = false;
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.authenticationService.logout();
        location.reload(true);
      }
      if (err.status === 500) {
        // auto logout token expired
        if (err.error.message.indexOf('expired') != -1) {
          this.authenticationService.logout();
          location.reload(true);
        }
      }
      let errorMsg = "";
      if (err.error.errors) {
        if (this.ObjectToArray(err.error.errors).length > 0) {
          this.ObjectToArray(err.error.errors).forEach((v, i) => {
            errorMsg += v.toString();
          });
        }
      }
      if (errorMsg == "") {
        errorMsg = err.error.message || err.statusText;
      }
      return throwError(errorMsg);
    }), finalize(() => {
      this.commonService.ShowLoader = false;
    }))
  }
  ObjectToArray(value) {
    if (!value) {
      return [];
    }
    let keyArr: any[] = Object.keys(value), dataArr = [];
    keyArr.forEach((key: any) => {
      var _ob = value[key];
      dataArr.push(_ob)
    });
    return dataArr;
  }
}

