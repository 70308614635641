import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService, CommonService } from '../_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private commonService: CommonService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url

    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.apiToken;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.apiToken}`
        }
      });
    }
    if(request.url.indexOf("vn/vendor/saveanswer")===-1
    && request.url.indexOf("vn/vendor/rfxcount")===-1
    && request.url.indexOf("vendor/api-impersonatelogin")===-1){
    this.commonService.ShowLoader = true;
    }
    return next.handle(request);
  }
}
