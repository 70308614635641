import { Component, OnInit, Input } from '@angular/core';
import { RfpService, CommonService } from '../_services';
import { environment } from '../../environments/environment';
import { DatePicker } from '../const';
import { ApiResponse } from '../_models/response';
import { FormGroup, FormControl } from '@angular/forms';
import { GerRfpParam } from '../_models';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-rfps',
  templateUrl: './rfps.component.html',
  styleUrls: ['./rfps.component.scss']
})
export class RfpsComponent implements OnInit {
  @Input() showDashboardBtn: boolean;
  @Input() status: string;
  @Input() subStatus: string;
  @Input() heading: string;
  @Input() icon: string;
  @Input() iconText: string;

  expand: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private rfpService: RfpService, private commonService: CommonService, private router: Router) { }
  public RoutePrefix = "";
  public rfps = [];
  public rfpsErrorMsg = "";
  public showFilter = false;
  public searchText = "";
  DatePicker = DatePicker;
  imageUrl = environment.serverUrl;
  searchFormGroup: FormGroup;
  filterCtrl = {
    SortBy: [
      { text: "Newest First", value: "latest" },
      { text: "Alphabetical Order", value: "alpha" }
    ],
    TimeLine: [
      { text: "Any", value: "any" },
      { text: "Quarter", value: "quarter" },
      { text: "Half a year", value: "half_year" },
      { text: "One year an more", value: "one_year_or_more" }
    ],
    Budget: [
      { text: "Any", value: "any" },
      { text: "Less than $100K", value: "less_than_100k" },
      { text: "100-500K", value: "between_100k_500k" },
      { text: "More than 500K", value: "more_than_500k" }

    ]
  }
  ngOnInit() {
    this.searchFormGroup = new FormGroup({
      SortBy: new FormControl('latest'),
      Budget: new FormControl('any'),
      TimeLine: new FormControl('any'),
    });
    this.bindRfp();
  }
  bindRfp() {
    // console.log(this.status);
    // console.log(this.subStatus);
    let p = new GerRfpParam();
    if (this.searchText)
      p.rfp_name = this.searchText;
    p.budget = this.commonService.GetFormValue(this.searchFormGroup, "Budget");
    p.sortby = this.commonService.GetFormValue(this.searchFormGroup, "SortBy");
    p.timeline = this.commonService.GetFormValue(this.searchFormGroup, "TimeLine");
    this.rfpService.get(this.status, this.subStatus, p).subscribe((res: ApiResponse) => {
      if (res.code == 200) {
        this.rfps = res.data[0];
        console.log('rfps ->', this.rfps);
      } else {
        this.rfpsErrorMsg = res.message;
      }
    }, err => {
      this.rfpsErrorMsg = err;
    })
  }
  selectedFilterText(filterType) {
    let text = "";
    if (filterType == "s") {
      let selectedVal = this.commonService.GetFormValue(this.searchFormGroup, "SortBy");
      text = this.filterCtrl.SortBy.filter((x) => { return x.value == selectedVal })[0].text;
    } else if (filterType == "t") {
      let selectedVal = this.commonService.GetFormValue(this.searchFormGroup, "TimeLine");
      text = this.filterCtrl.TimeLine.filter((x) => { return x.value == selectedVal })[0].text;
    } else if (filterType == "b") {
      let selectedVal = this.commonService.GetFormValue(this.searchFormGroup, "Budget");
      text = this.filterCtrl.Budget.filter((x) => { return x.value == selectedVal })[0].text;
    }
    return text;
  }
  resetFilterAll() {
    this.commonService.SetFormValue(this.searchFormGroup, "SortBy", this.filterCtrl.SortBy[0].value);
    this.commonService.SetFormValue(this.searchFormGroup, "TimeLine", this.filterCtrl.TimeLine[0].value);
    this.commonService.SetFormValue(this.searchFormGroup, "Budget", this.filterCtrl.Budget[0].value);
    this.bindRfp();
    this.showFilter = false;
  }
  searchItems() {
    this.showFilter = false;
    this.bindRfp();
  }
  getTimeLine(dt) {
    return (moment(DatePicker.ApiToUiFormat(dt).DateObject).fromNow(true)).toUpperCase();
  }

  doubleClick(RoutePrefix, r_id, status, subStatus) {
    // /rfp-dashboard/{{r.id}}/{{status}}/{{subStatus}}

    console.log('RoutePrefix', RoutePrefix);
    console.log('r_id', r_id);
    console.log('status', status);
    console.log('subStatus', subStatus);

    this.router.navigate([`${RoutePrefix}/rfp-dashboard/${r_id}/${status}/${subStatus}`]);
  }

  read_more() {
    this.expand = !this.expand;
  }

  isExpired(rfp: any) {
    return this.rfpService.isRfpExpired(rfp);
  }
}
