import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiResponse } from 'src/app/_models/response';
import { DashboardStoreService, RfpService, VenderService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import {ToastService} from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';
import { CheckboxComponent } from 'ng-uikit-pro-standard';
@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  @Input('rfp_status') rfpStatus: string;
  @Input('rfp_sub_status') subStatus: string;
  @Input("rfp_id") RfpId: string;
  @Output('showConfirmPaymentBox') showConfirmPaymentBoxEvent = new EventEmitter();
  @Input("rfp_delegate_status") delegateStatus: string;
  @ViewChild("confirmcheckbox", { static: false }) confirmcheckbox: CheckboxComponent;
  @Input() canEdit: boolean;
  isnotConfirmed = true;
  public AllReviews = [];
  public AllWorksheetTabs=[];
  public AllAppendix = [];
  options:any;
  isRfpCompleted: boolean = false;
  isAllSectionDone: boolean = true;
  isWorksheetDone:boolean=true;
  tabsConfig: any;
  isWorksheetSkipped: boolean = false;


  constructor(private router: Router,
    private rfpService: RfpService,
    private venderService: VenderService,
    public dashboardStoreService: DashboardStoreService,
    private toastService:ToastService) {
      this.options={ opacity: 1 }
     }


  ngOnInit() {
    this.dashboardStoreService.rfpCompleted$.subscribe((res: any) => {
      this.isRfpCompleted = res;
      if (this.AllReviews.length == 0) {
        this.getReviewTab();
      }
    })
    this.dashboardStoreService.getTabsConfig().subscribe((res: any) => {
      this.tabsConfig = res;
    })
    if (this.AllReviews.length == 0) {
      this.getReviewTab();
    }
  }

  getReviewTab() {
    if((this.rfpStatus=='accepted' && this.tabsConfig.show_all) || this.subStatus=='sent'){
    this.rfpService.reviewRfpCompletion(this.RfpId).subscribe((x: ApiResponse) => {
      this.isAllSectionDone = true;
      this.isWorksheetDone=true;
      this.AllReviews = [];
      if (x.code == 200) {
        if (x.data.length > 0) {
          let data = x.data[0];
          this.AllReviews = data.section;
          this.AllReviews.forEach((review: any) => {
            if (!review.marked_section) {
              this.isAllSectionDone = false;
              this.confirmcheckbox.checked=false;
              this.isnotConfirmed=true;
            }
          });
          this.AllWorksheetTabs = data.worksheet;
          // console.log(this.AllWorksheetTabs);
          this.isWorksheetSkipped = data.is_skipped_worksheet;
          this.AllWorksheetTabs.forEach((worksheet: any) => {
            if (!worksheet.status && this.isWorksheetSkipped == false) {
              this.isWorksheetDone = false;
              this.confirmcheckbox.checked=false;
              this.isnotConfirmed=true;
            }
          });
          let isThereAnyRequiredColumnInWorksheet = localStorage.getItem('has_required');
          if(isThereAnyRequiredColumnInWorksheet === 'true'){
            console.log('here is true');
            let isRequiredFieldHasData = localStorage.getItem('worksheet_required_filled');
            console.log(isRequiredFieldHasData);
            if(isRequiredFieldHasData === undefined || isRequiredFieldHasData === 'false' || isRequiredFieldHasData === null){
              this.isWorksheetDone = false;
              // this.toastService.error("Please fill worksheet required fields");
            }else{
              this.isWorksheetDone = true;
            }
          }
        }
      }
    }, err => {
    });
  }
  }

  showConfirmPaymentBox1() {
    this.showConfirmPaymentBoxEvent.emit("");
  }

  checkAllSectionCompleted(event) {
    console.log(this.isWorksheetDone);
    if (event.checked) {
      if (!this.isAllSectionDone) {
        this.toastService.error('Please complete all RFx question sections','RFx Not Completed',this.options);
        this.confirmcheckbox.checked = !this.confirmcheckbox.checked;
      }
      else if(!this.isWorksheetDone) {
        this.toastService.error('Please complete worksheet','RFx Not Completed',this.options);
        this.confirmcheckbox.checked = !this.confirmcheckbox.checked;
      }
      else{
        this.isnotConfirmed = false;
      }
    }
    else{
      this.isnotConfirmed=true;
    }
  }

  submitVendorRFx() {
    this.venderService.submitVendorRFxDetail(this.RfpId).subscribe((s: ApiResponse) => {
      if (s.code == 200) {
        this.router.navigate(['/my-rfps']);
      }
    }, err => {
      // this.sharedMethodService.setError(err);
      // this.ReviewError = err;
    });
  }

}
