import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService, CommonService, UserService } from '../../_services';
import { VenderService } from '../../_services/vender.service';
import { ApiResponse } from '../../_models/response';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-verified',
  templateUrl: './verified.component.html',
  styleUrls: ['./verified.component.scss']
})
export class VerifiedComponent implements OnInit {

  MainForm: FormGroup;
  loading = false;
  readonly = true;
  submitted = false;
  returnUrl: string;
  Rperror = '';
  rplinkSent = false;
  rplinkSentMsg = '';
  isAccepted=true;
  id = '';
  token = '';
  paymentTermsLink="";
  privacyLink="";
  public email = '';
  public company_name = '';
  public company_name_set = false;
  public company_id = 0;
  showHideValidatorPopup: any = false;
  hasNumber:any = false;
  hasUpper:any = false;
  hasLower:any = false;
  hasNumeric:any = false;
  hasSymbol:any = false;
  hasMinChars:any = false;
  hasMaxChars:any = false;
  hasError: any = true;
  passwordType: any = 'password';
  confPasswordType: any = 'password';

  viewPasswordIcon:any = 'eye-slash';
  viewConfPasswordIcon:any = 'eye-slash';
  passwordText:any = '';
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private vendorService: VenderService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.auth.getPaymentTerms().subscribe((res:any)=>{
      if(res.code==200){
      this.paymentTermsLink=res.data[0].terms;
      this.privacyLink=res.data[0].privacy;
      }
    });
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.MainForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      firstName: ['',[ Validators.required]],
      lastName: [''],
      company: ['',[Validators.required]],
    });
    //http://localhost:4200/verified?id=MTA1&token=9ac88f2fe84d44ffcc8da418e2785768
    //amitaspdev@gmail.com
    this.id = this.commonService.GetQueryStringValue("id")
    this.token = this.commonService.GetQueryStringValue('token');
    this.userService.getPublicUser(atob(this.id)).subscribe((x: ApiResponse) => {
      console.log('x1',x);
      if (x.code == 200) {
        let u = x.data[0];

        this.commonService.SetFormValue(this.MainForm, "firstName", u.fname)
        this.commonService.SetFormValue(this.MainForm, "lastName", u.lname)
        this.commonService.SetFormValue(this.MainForm, "company", u.company.name)
        this.company_name = u.company.name;
        this.company_name_set = !u.company.name ? false : true;
        console.log(u.company.name);
        if(u.company.name =="" || u.company.name == null){
          this.readonly = false;
        }
        this.email = u.email;
      } else {
        this.Rperror = x.message;
      }
    }, err => {
      this.Rperror = err;
    });
  }
  onSubmit() {
    this.Rperror = "";
    if (this.MainForm.valid) {
      this.loading = true;
      let password = this.commonService.GetFormValue(this.MainForm, 'password');
      let confirmPassword = this.commonService.GetFormValue(this.MainForm, 'confirmPassword');
      let firstName = this.commonService.GetFormValue(this.MainForm, 'firstName');
      let lastName = this.commonService.GetFormValue(this.MainForm, 'lastName');
      let company = this.commonService.GetFormValue(this.MainForm, 'company');
      this.vendorService.vendorupdate(this.MainForm.value,this.id, this.token).subscribe((x: ApiResponse) => {

        if (x.code == 200) {
          this.rplinkSent = true;
          this.rplinkSentMsg = x.message;
          if(this.email){
            // auto login start
           localStorage.removeItem("vendorRememberMe");
            localStorage.removeItem("vendorUserName");
            localStorage.removeItem("vendorPassword");
            this.loading = true;
            this.auth.login(this.email, password).then((res: any) => {
              this.loading = false;
              if (res.code == 200) {

                  localStorage.setItem("vendorRememberMe", 'true');
                  localStorage.setItem("vendorUserName", this.email);
                  localStorage.setItem("vendorPassword", password);

                //window.location.href = "/home";
                //Complete load require to manage the Local storage
                if (this.returnUrl && this.returnUrl !== '/') {
                  this.router.navigate([this.returnUrl]);
                } else {
                  this.router.navigate(['/']);
                }
              }
            }, (err) => {
              this.loading = false;
              console.log(err);
              if (err.status == 500) {
                localStorage.clear();
                this.router.navigate(['/login']);
              } else {
                this.Rperror = err;
              }
              // this.errors = error.error.message;
            });
          }
          // auto login end

        } else {
          this.Rperror = x.message;
          console.log('else1');
        }
        this.loading = false;
      }, err => {
        this.Rperror = err;
        this.loading = false;
      });
    }
    else {
      console.log('else3');
      this.commonService.MarkAllAllCtrlDirty(this.MainForm);
    }
  }

  checkIFAccepted(event){
    if (event.checked) {
      this.isAccepted=false;
    }
    else{
      this.isAccepted=true;
    }
  }


  viewPassword(){
    this.viewPasswordIcon = (this.viewPasswordIcon != 'eye' )?'eye': 'eye-slash';
    this.passwordType =  (this.viewPasswordIcon == 'eye' )?'text': 'password';
  }

  confViewPassword(){
    this.viewConfPasswordIcon = (this.viewConfPasswordIcon != 'eye' )?'eye': 'eye-slash';
    this.confPasswordType =  (this.viewConfPasswordIcon == 'eye' )?'text': 'password';
  }

  validatePassword(e){
    let value = e.target.value;
    this.passwordText = value;
    console.log(value.length);
    (value.toString().length > 0 && value.toString().length <= 20)? this.hasMaxChars = true : this.hasMaxChars = false;
    (value.length >= 8)? this.hasMinChars = true : this.hasMinChars = false;
    this.hasNumeric = /\d/.test(value);
    this.hasUpper = /[A-Z]/.test(value);
    this.hasLower = /[a-z]/.test(value);
    let format = /[!@#^&*()+_,.{}?-]/;
    this.hasSymbol = format.test(value);
    if(!this.hasMaxChars || !this.hasNumeric || !this.hasUpper || !this.hasLower || !this.hasSymbol || !this.hasMinChars){
      this.hasError = true;
    }else{
      this.hasError = false;
    }
  }

  isConfirmedPassword(e){
    if(this.passwordText != e.target.value){
      this.hasError = true;
    }else{
      this.hasError = false;
    }
  }

  showHideValidator(showHide,e){
    if(showHide == 'show'){
      this.showHideValidatorPopup = true;
    }else{
      this.showHideValidatorPopup = false;
    }
    this.validatePassword(e);
  }
}
