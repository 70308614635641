import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiResponse } from '../_models/response';
import { AuthenticationService, UserService, CommonService } from '../_services';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment.prod';

@Component({
  selector: 'app-my-profile-edit',
  templateUrl: './my-profile-edit.component.html',
  styleUrls: ['./my-profile-edit.component.scss']
})
export class MyProfileEditComponent implements OnInit {
  userId = 0;
  ProfileUpdateForm: FormGroup;
  profileformError = "";
  RoutePrefix = "";
  constructor(private auth: AuthenticationService,
    private userService: UserService,
    public commonservice: CommonService,
    private router: Router) { }

  ngOnInit() {
    this.userId = this.auth.currentUserValue.userid;
    this.ProfileUpdateForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phoneno: new FormControl('', [Validators.required])
    });
    this.profileformError = "";

    this.userService.getUser(this.userId).subscribe((r: ApiResponse) => {

      if (r.code == 200) {
        let d = r.data[0];
        if (d) {
          this.commonservice.SetFormValue(this.ProfileUpdateForm, "firstName", d.fName);
          this.commonservice.SetFormValue(this.ProfileUpdateForm, "lastName", d.lName);
          this.commonservice.SetFormValue(this.ProfileUpdateForm, "phoneno", d.phone);

        } else {
          this.userId = 0;
          this.profileformError = r.message;
        }
      }
      else {
        this.profileformError = r.message;
      }
    }, err => {
      this.profileformError = err;
    })


  }

  updateprofile() {
    if (this.ProfileUpdateForm.valid) {
      this.profileformError = "";
      //update
      this.userService.updateCurrentProfile(this.ProfileUpdateForm.value).subscribe((u: ApiResponse) => {
        if (u.code == 200) {
          this.router.navigate([this.RoutePrefix + '/my-profile']);
        } else {
          this.profileformError = u.message;
        }
      }, err => {
        this.profileformError = err;
      });
    } else {
      this.commonservice.MarkAllAllCtrlDirty(this.ProfileUpdateForm);
    }
  }
}
