import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Rfp } from 'src/app/_models/rfp';
import { DashboardStoreService,RfpService } from 'src/app/_services';
import { environment } from 'src/environments/environment';
import { DatePicker } from '../../../const';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public rfp$: Observable<Rfp>;
  files: any = [];
  public DatePicker = DatePicker;

  @Input("rfp_id") RfpId: string;
  @Input("paymentNeeded") paymentNeeded: string;
  @Input("rfp_delegate_status") delegateStatus: string;
  @Input("rfp_status") rfpStatus: string;
  @Input("rfp_sub_status") rfpSubStatus: string;
  public ProgressChart = {
    color: "43D1C3",
    animate: {},
    NotStarted: 0,
    InProgress: 0,
    Complete: 0,
  }
  constructor(
    public dashboardStoreService: DashboardStoreService,
    public rfpService: RfpService
  ) { }

  ngOnInit() {
    this.rfp$ = this.dashboardStoreService.getRfpDetails();
    this.getSetupFiles();
  }

  getSetupFiles() {
    this.dashboardStoreService.getSetupFiles().subscribe((res: any) => {
      if(res){
      res.data[0].forEach(element => {
        console.log("files",element);
        this.files.push(element);
      });
    }
    });
  }

  reloadCharts() {
    this.dashboardStoreService.reloadCharts();
    this.rfp$ = this.dashboardStoreService.getRfpDetails();
  }

  getIcon(ext:string){
  return `assets/images/${ext}.png`;
  }

  getLink(path: string) {
    // var path = path.replace(/storage/g, "download/attachment");
    return `${environment.serverUrl}${path}`;
  }
}
