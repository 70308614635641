import { Component } from '@angular/core';
import { Router } from '@angular/router';

import {AuthenticationService, VenderService} from './_services';
import { User } from './_models';
import { environment } from '../environments/environment';
import { ApiResponse } from './_models/response';
import { RfpService } from './_services';
import { interval, Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  year = (new Date()).getFullYear();
  currentUser: User;
  RoutePrefix = "";
  Stripe: any;
  rfxcount: any = {};
  subs = new Subscription();
  notifications = [];
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private rfpService: RfpService,
    private vendorService: VenderService
  ) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser) {
        setTimeout(() => {
          this.rfpService.getRfxCounts().subscribe((u: ApiResponse) => {
            if (u.code == 200) {
              this.rfxcount = u.data;
            }
          });
        }, 1000);
      }
    });
  }
  ngOnInit() {
    //To reload links count we are call the count api in every 10 seconds interval.
    this.subs = interval(10000).subscribe(() => {
      this.reloadLinks();
    });

    this.vendorService.newAnswerSubject.subscribe((res: any) => {
      this.notifications.push({
        id: res.id,
        text: `${res.replied_by.fName} ${res.replied_by.lName} replied to your question for ${res.question.rfp.name}. Click to view answers`,
        link:  `rfp/${res.rfp_id}/questions`,
      });
    });

    this.vendorService.answersViewed.subscribe((answerIds: string[]) => {
      this.notifications = this.notifications.filter(n => answerIds.indexOf(n.id) === -1);
    });
  }

  addRemoveActive(e: any) {
    var elems = document.querySelectorAll(".waves-light");

    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
    });
    e.currentTarget.children[0].classList.add("active");
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  showHideSideNav(sidenav) {
    //if (sidenav.shown)
    //  sidenav.hide()
    //else
    //  sidenav.show()
  }

  //call the method on every header nav bar link click to reload invites count.
  reloadLinks() {
    this.rfpService.getRfxCounts().subscribe((u: ApiResponse) => {
      if (u.code == 200) {
        this.rfxcount = u.data;
      }
    });
  }

  hasNotifications() {
    return this.notifications && this.notifications.length;
  }
}
