import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentVendorUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return new Promise((resolve, reject) => {

      this.http.post(`${environment.apiUrl}/api-login`, { email: email, password: password, type: "vn" }).subscribe((user: any) => {
        if (user && user.data) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          if (user.data.role.name.toLocaleLowerCase() == 'vendoradmin') {
            user.data.isAdmin = true;
          }
          localStorage.setItem('currentVendorUser', JSON.stringify(user.data));
          this.currentUserSubject.next(user.data);
        }
        resolve(user);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  impersonatelogin(type: string, token: string, id) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiUrl}/impersonate`, { type: type, token: token, id: id }).subscribe((user: any) => {
        if (user && user.data) {
          if (user.data.role.name.toLocaleLowerCase() == 'vendoradmin') {
            user.data.isAdmin = true;
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentVendorUser', JSON.stringify(user.data));
          this.currentUserSubject.next(user.data);
        }
        resolve(user);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  register(user) {
    return this.http.post(`${environment.apiUrl}/vendor/api-register`, user);
  }
  forgotPassword(email) {
    return this.http.post(`${environment.apiUrl}/forgot/password`, { email: email });
  }
  //change/user/password
  changePassword(oldpassword, newpassword, confirmpassword) {
    return this.http.put(`${environment.apiUrl}/change/user/password`, { oldpassword: oldpassword, newpassword: newpassword, confirmpassword: confirmpassword });
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentVendorUser');
    this.currentUserSubject.next(null);
  }
  resetPassword(newpassword, confirmpassword, id, token) {
    return this.http.put(`${environment.apiUrl}/reset/password/${id}/${token}`, { newpassword: newpassword, confirmpassword: confirmpassword });
  }
  getresetPassword(id, token) {
    return this.http.get(`${environment.apiUrl}/resetpassword/${id}/${token}`, {});
  }

  getPaymentTerms() {
    return this.http.get(`${environment.apiUrl}/settings/terms`);
  }
}
