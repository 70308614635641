import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './public/login/login.component';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MomentModule } from 'angular2-moment';
import { RegisterComponent } from './public/register/register.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { DragulaModule } from 'ng2-dragula';
import { LoaderComponent } from './_shared/loader/loader.component';
import { ConfirmComponent } from './_shared/confirm/confirm.component';
import { DashboardComponent } from './rfps/rfp-dashboard/dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyProfileEditComponent } from './my-profile-edit/my-profile-edit.component';
import { MyCompanyEditComponent } from './my-company-edit/my-company-edit.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MyRfpsComponent } from './rfps/my-rfps/my-rfps.component';
import { InProgressRfpsComponent } from './rfps/in-progress-rfps/in-progress-rfps.component';
import { AvailableRfpsComponent } from './rfps/available-rfps/available-rfps.component';
import { RfpDashboardComponent } from './rfps/rfp-dashboard/rfp-dashboard.component';
import { RfpsComponent } from './rfps/rfps.component';
import { VendorsComponent } from './vendors/vendors.component';
import { VerifyComponent } from './public/verify/verify.component';
import { VendorAddEditComponent } from './vendor-add-edit/vendor-add-edit.component';
import { VerifiedComponent } from './public/verified/verified.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { DelegateAddEditComponent } from './delegate-add-edit/delegate-add-edit.component';
import { DelegateRfpsComponent } from './rfps/delegate-rfps/delegate-rfps.component';
import { ElementRefDirectiveDirective } from './_shared/element-ref-directive.directive';
import { HideMeDirective } from './hide-me.directive';
import { BackgroundComponent } from './rfps/rfp-dashboard/background/background.component';
import { WorksheetComponent } from './rfps/rfp-dashboard/worksheet/worksheet.component';
import { AddDelegateComponent } from './rfps/rfp-dashboard/add-delegate/add-delegate.component';
import { QuestionsComponent } from './rfps/rfp-dashboard/questions/questions.component';
import { ReviewComponent } from './rfps/rfp-dashboard/review/review.component';
import { QuestionnaireComponent } from './rfps/rfp-dashboard/questions/questionnaire/questionnaire.component';
import { RfpDashboardResolver} from './rfps/rfp-dashboard/rfp-dashboard.resolver';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { StripePaymentModule } from './_shared/stripe-payment/stripe-payment.module';
import { ImpersonateComponent } from './public/impersonate/impersonate.component';
import { RfpQuestionsComponent } from './rfps/rfp-questions/rfp-questions.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,

    RegisterComponent,
    ForgotPasswordComponent,
    LoaderComponent, ConfirmComponent, DashboardComponent, MyProfileComponent, MyProfileEditComponent, MyCompanyEditComponent,
    ChangePasswordComponent,
    MyRfpsComponent,
    InProgressRfpsComponent,
    AvailableRfpsComponent,
    RfpDashboardComponent,
    RfpsComponent,
    VendorsComponent,
    VerifyComponent,
    VendorAddEditComponent,
    VerifiedComponent,
    ResetPasswordComponent,
    DelegateAddEditComponent,
    DelegateRfpsComponent,
    ElementRefDirectiveDirective,
    HideMeDirective,
    BackgroundComponent,
    WorksheetComponent,
    AddDelegateComponent,
    QuestionsComponent,
    ReviewComponent,
    QuestionnaireComponent,
    ImpersonateComponent,
    RfpQuestionsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModulesPro.forRoot(),
    DragulaModule.forRoot(),

    AngularMultiSelectModule,
    MomentModule,
    NgxMaskModule.forRoot(),
    StripePaymentModule,
    ToastModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    MDBSpinningPreloader,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    RfpDashboardResolver,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmComponent]
})
export class AppModule { }
