import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService, UserService, AuthenticationService } from '../_services';
import { RoleService } from '../_services/role.service';
import { ApiResponse } from '../_models/response';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-vendor-add-edit',
  templateUrl: './vendor-add-edit.component.html',
  styleUrls: ['./vendor-add-edit.component.scss']
})
export class VendorAddEditComponent implements OnInit {
  IsEdit = false;
  UserId = 0;
  IsCloned = false;

  constructor(
    public commonservice: CommonService,
    private userService: UserService,
    public roleService: RoleService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router
  ) {
  }

  AddUser: FormGroup;
  Roles: Array<any>;
  userFormError = "";

  ngOnInit() {
    this.AddUser = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      emailid: new FormControl('', [Validators.required]),
      roles: new FormControl(''),
      phoneno: new FormControl('', [Validators.required]),
      invitationmessage: new FormControl('')
    });

    this.roleService.getAllVendorRoles().subscribe((r: any) => {
      let p = [];
      r.data[0].forEach((obj) => {
        p.push({ value: obj.id, label: (obj.name).toUpperCase() });
      });
      this.Roles = p;
    });
    this.activatedRoute.params.subscribe((param: any) => {
      if (param.Id) {
        this.UserId = param.Id;

        this.userFormError = "";
        this.userService.getvendor(this.UserId).subscribe((r: ApiResponse) => {

          if (r.code == 200) {
            let d = r.data[0];
            if (d) {
              this.IsEdit = true;

              this.commonservice.SetFormValue(this.AddUser, "firstName", d.fName);
              this.commonservice.SetFormValue(this.AddUser, "lastName", d.lName);
              this.commonservice.SetFormValue(this.AddUser, "emailid", d.email);

              this.commonservice.SetFormValue(this.AddUser, "roles", d.role_id);
              // this.commonservice.SetFormValue(this.AddUser, "password", d.password);
              this.commonservice.SetFormValue(this.AddUser, "phoneno", d.phone);


            } else {
              this.IsEdit = false;
              this.UserId = 0;
              this.userFormError = r.message;
            }
          }
          else {
            this.userFormError = r.message;
          }
        }, err => {

          this.userFormError = err;
        })
      }
      else {

        this.userService.getUser(this.auth.currentUserValue.userid).subscribe((u: ApiResponse) => {
          let u1 = u.data[0];
          let name = u1.fName;
          if (u1.lName) {
            name += " " + u1.lName;
          }
          let msg = `You are invited by ${name}`;
          if (u1.company) {
            if (u1.company.name) {
              msg += ` to be a part of ${u1.company.name}`;
            }
          }

          this.commonservice.SetFormValue(this.AddUser, "invitationmessage", msg);
        })
      }
    });

  }

  saveuser() {
    if (this.AddUser.valid) {
      this.userFormError = "";
      if (this.IsEdit) {
        //Update
        this.userService.updateuser(this.AddUser.value, this.UserId).subscribe((u: ApiResponse) => {
          if (u.code == 200) {
            this.router.navigate(['/vendors']);
          } else {
            this.userFormError = u.message;
          }
        }, err => {
          this.userFormError = err;
        });
      } else {
        //Save
        this.userService.postvendor(this.AddUser.value).subscribe((u: ApiResponse) => {
          if (u.code == 200) {
            this.router.navigate(['vendors']);
          } else {
            this.userFormError = u.message;
          }
        }, err => {
          this.userFormError = err;
        });
      }
    } else {
      this.commonservice.MarkAllAllCtrlDirty(this.AddUser);
    }
  }
}
