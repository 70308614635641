import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class RoleService {
  constructor(private http: HttpClient) { }

  getAllRoles() {
    return this.http.get(`${environment.apiUrl}/roles`);
  }
  getAllVendorRoles() {
    return this.http.get(`${environment.apiUrl}/vendorRoles`);
  }
}
