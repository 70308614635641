import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService, CommonService } from '../../_services';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  verifyForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  id :any;
  token: string;
  error = '';
  isSuccess: boolean;
  successMsg = "You Account has Activated Successfully!";
  RoutePrefix = "";
  constructor(private router: Router, private commonService:CommonService, private userService: UserService) { }

  ngOnInit() {
    this.id = this.commonService.GetQueryStringValue("id")
    this.token = this.commonService.GetQueryStringValue('token');
    if(!this.id||!this.token){
      this.router.navigate(['/login']);
    }
    this.verifyUser(this.id, this.token);
  }

  verifyUser(id, token){
    this.userService.verifyVnRegisterUser(id, token).subscribe((res: any) => {
      if (res.code == 200) {
        this.successMsg = res.message;
        this.isSuccess = true;
      }
    }, (err) => {
      this.loading = false;
      this.error = err;
    });
  }
}
