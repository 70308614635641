import { Component, OnInit } from '@angular/core';
import { VenderService } from "../_services/vender.service";
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-delegate-add-edit',
  templateUrl: './delegate-add-edit.component.html',
  styleUrls: ['./delegate-add-edit.component.scss']
})
export class DelegateAddEditComponent implements OnInit {

  public users: Array<any> = [];
  public showAssignBtn = false;
  public readyToAssign = [];
  public assignStr = '';
  public rfx_id = '';
  public serverError = "";
   dataSource: any = [];

  constructor(
    public venderService: VenderService,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      console.log('params', params);
      this.rfx_id = params['rfxid'];
      this.getDeligatesByVendorAdmin();
    });
    
  }

  getDeligatesByVendorAdmin() {

    this.venderService.getDeligatesByVendorAdmin( this.rfx_id ).subscribe( (result: any) => {
      console.log('result', result);
      if( result.data.length > 0 ) {

        console.log('typeof result.data', typeof result.data);

        this.users = result.data[0];
      } else {
        this.users = [];
      }
    } );
  }

  selectUnselectVendors( event, vendor_id ) {
    console.log( 'event.currentTarget.checked', event.currentTarget.checked );

    if( event.currentTarget.checked ) {

      if( !this.readyToAssign.includes( vendor_id ) ) {
        this.readyToAssign.push(vendor_id);
      }
    } else {
      if( this.readyToAssign.includes( vendor_id ) ) {
        
        var index = this.readyToAssign.indexOf( vendor_id );
        if (index > -1) {
            this.readyToAssign.splice(index, 1);
        }
      }
    }

    console.log('this.readyToAssign', this.readyToAssign);
  }

  getDelicateString() {

    return this.assignStr = this.readyToAssign.join('-');
  }

  assignDeligateToRfx( rfxId, vendorStr ) {

    let in_data = {
      delegateId: vendorStr
    };
    this.venderService.assignDelegatesToRfx( rfxId, in_data ).subscribe( (result : any) => {
      console.log('result', result);

      if( result.code == 200 ) {

        Swal.fire('Deligate Assigned', 'Deligate assigned successfully.', 'success');
        this.getDeligatesByVendorAdmin();
      } else {
        // alert( result.message );
        Swal.fire('Error', result.message, 'error');
      }
    } );
  }
}
