import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import{StripePaymentComponent} from './stripe-payment.component'
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxStripeModule } from 'ngx-stripe';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';


@NgModule({
  declarations: [StripePaymentComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxStripeModule.forRoot(),
    MDBBootstrapModulesPro.forRoot()
  ],
  exports:[StripePaymentComponent]
})
export class StripePaymentModule { }

