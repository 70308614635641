import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService, VenderService} from '../../_services';
import {ActivatedRoute} from '@angular/router';
import {ToastService} from 'ng-uikit-pro-standard';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-rfp-questions',
  templateUrl: './rfp-questions.component.html',
  styleUrls: ['./rfp-questions.component.scss']
})
export class RfpQuestionsComponent implements OnInit, OnDestroy {

  @ViewChild('questionModal', { static: true }) questionModal: any;

  rfpId: string;

  loading: boolean;

  question = '';

  qas: any[];

  questionsPage = 1;
  questionsLastPage: number;
  questionsTotalRows: number;
  questionsFrom: number;
  questionsTo: number;

  subs: Subscription[] = [];

  constructor(private activatedRoute: ActivatedRoute,
              private auth: AuthenticationService,
              private vendorService: VenderService,
              private toastService: ToastService) { }

  ngOnInit() {
    const paramSub = this.activatedRoute.params.subscribe((param: any) => {
      if (param.id) {
        this.rfpId = param.id;
        this.getQuestions();
      }
    });
    this.subs.push(paramSub);

    this.getQuestions();

    const newAnswerSub = this.vendorService.newAnswerSubject.subscribe(() => {
      this.getQuestions();
    });

    this.subs.push(newAnswerSub);
  }

  getQuestions() {
    this.loading = true;
    const questionsSub = this.vendorService.getQuestions(this.rfpId, this.questionsPage)
      .subscribe((res: any) => {
        this.qas = res.questions.data;
        this.questionsFrom = res.questions.from;
        this.questionsTo = res.questions.to;
        this.questionsTotalRows = res.questions.total;
        this.questionsLastPage = res.questions.last_page;
        this.qas = res.questions.data;
        this.loading = false;

        const answersIds = [];
        res.questions.data.forEach(question => {
          question.answers.forEach(answer => {
            answersIds.push(answer.id);
          });
        });
        this.vendorService.answersViewed.next(answersIds);
      }, (err) => {
        console.log(err);
        this.loading = false;
      });

    this.subs.push(questionsSub);
  }

  getAuthorName(author: any) {
    const user = this.auth.currentUserValue;
    if (user.userid === author.id) {
      return 'me';
    } else {
      return author.fName + ' ' + author.lName;
    }
  }

  createQuestion(question: string) {
    // save question
    const newQuestionSub = this.vendorService.createQuestion(this.rfpId, question)
      .subscribe((res: any) => {
        this.toastService.success('Your question has been sent to the buyer', '', { opacity: 1 });
        this.question = '';
        this.questionModal.hide();
        this.getQuestions();
      }, (err) => {
        this.toastService.error(err, '', { opacity: 1 });
      });
    this.subs.push(newQuestionSub);
  }

  goToFirstPage() {
    this.questionsPage = 1;
    this.getQuestions();
  }

  goToLastPage() {
    this.questionsPage = this.questionsLastPage;
    this.getQuestions();
  }

  goToNextPage() {
    this.questionsPage++;
    this.getQuestions();
  }

  goToPreviousPage() {
    this.questionsPage--;
    this.getQuestions();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
