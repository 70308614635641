import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-available-rfps',
  templateUrl: './available-rfps.component.html',
  styleUrls: ['./available-rfps.component.scss']
})
export class AvailableRfpsComponent implements OnInit {


  constructor() { }
  ngOnInit() {
  }

}
