import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FormGroup } from '@angular/forms';
import { retry } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class CommonService {
  constructor(private http: HttpClient) { }
  public ShowLoader = false;
  public forgotPassSent=false;
  MarkAllAllCtrlDirty(frm: FormGroup) {
    Object.values(frm.controls).forEach(control => {
      control.markAsDirty();
      control.markAsTouched();
    });

  }

  SetFormValue(frm: FormGroup, name: string, value: any) {
    frm.controls[name].setValue(value);
  }
  GetFormValue(frm: FormGroup, name: string) {
    return frm.controls[name].value;
  }
  GetQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
  }
}
